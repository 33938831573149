import AdminSvc from "@/modules/admin-panel/core/admin.service.js";

export default {
  namespaced: true,

  state: () => ({
    order: null,
    looks: [],
    isLoading: false,
  }),
  mutations: {
    updateOrder(state, payload) {
      state.order = payload;
    },
    updateLooks(state, payload) {
      state.looks = payload.map((look) => ({
        ...look,
        look_result: look.results?.[0] ?? null,
      }));
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    async order({ commit }, { api, id }) {
      const response = await new AdminSvc(api).order(id);
      commit("updateOrder", response);
    },
    async setLooks({ commit, state }, { api, id, cache }) {
      if (cache && state.looks.length) return state.looks;
      const response = await new AdminSvc(api).looks(id);
      commit("updateLooks", response);
    },
    setLoader({ commit }, value) {
      commit("setLoading", value);
    },
  },
  getters: {
    orderHasRounds(state) {
      return state.order?.feedback_rounds?.length > 1 ?? false;
    },
    orderRounds(state) {
      return state.order?.feedback_rounds.slice(1) ?? [];
    },
    activeRound(state) {
      return (
        state.order?.feedback_rounds?.[
          state.order.feedback_rounds.length - 1
        ] ?? null
      );
    },
  },
};
