import axios from "axios";
import qs from "qs";

export default class AxiosHttp {
  get(url, config) {
    config.paramsSerializer = (params) =>
      qs.stringify(params, { arrayFormat: "repeat" });

    return axios
      .get(url, config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  post(url, data, config) {
    config.paramsSerializer = (params) =>
      qs.stringify(params, { arrayFormat: "repeat" });

    return axios
      .post(url, data, config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  delete(url, data, config) {
    config.paramsSerializer = (params) =>
      qs.stringify(params, { arrayFormat: "repeat" });

    return axios
      .delete(url, data, config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  request(url, config) {
    config.paramsSerializer = (params) =>
      qs.stringify(params, { arrayFormat: "repeat", indices: false });
    return axios
      .request(url, config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  requestHeaders(url, config) {
    config.paramsSerializer = (params) =>
      qs.stringify(params, { arrayFormat: "repeat", indices: false });
    return axios
      .request(url, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
