import { render, staticRenderFns } from "./BaseCheckBox.vue?vue&type=template&id=1250a881&scoped=true&"
import script from "./BaseCheckBox.vue?vue&type=script&lang=js&"
export * from "./BaseCheckBox.vue?vue&type=script&lang=js&"
import style0 from "./BaseCheckBox.vue?vue&type=style&index=0&id=1250a881&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1250a881",
  null
  
)

export default component.exports