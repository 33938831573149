<template>
  <div class="page-content">
    <DefaultHeader @toggle-sidebar="sidebarActive = !sidebarActive" is-admin />
    <main class="main main_d-flex">
      <div class="dashboard-content">
        <div class="dashboard-content__row">
          <div
            class="dashboard-content__sidebar"
            :class="{ isActive: sidebarActive }"
          >
            <SideBar
              @collapse="sidebarActive = !sidebarActive"
              :is-admin="$options.adminVariant"
            />
          </div>
          <div class="dashboard-content__inner">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import DefaultHeader from "@/components/layouts/DefaultHeader.vue";
import SideBar from "@/components/ui/elements/SideBar.vue";
export default {
  adminVariant: true,
  data() {
    return {
      sidebarActive: false,
    };
  },
  components: {
    DefaultHeader,
    SideBar,
  },
};
</script>
