<template functional>
  <el-tag
    v-bind="props"
    class="app-label"
    :class="{ variant_clickable: props.clickable }"
    @click="props.clickable ? listeners['click']() : null"
    ><slot></slot
  ></el-tag>
</template>

<script>
export default {
  props: {
    clickable: Boolean,
    type: String,
    effect: String,
    size: String,
  },
};
</script>

<style lang="scss">
.app-label.el-tag {
  display: inline-flex;
  align-items: center;
  height: auto;
  white-space: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  &.el-tag--warning {
    border: unset;
    background-color: $warning;
    color: $warning-text;
  }
  &.el-tag--sm {
    height: 24px;
    line-height: 22px;
  }
  &.el-tag--md {
    height: 40px;
    line-height: 38px;
  }
  &.el-tag--mini {
    font-size: 12px;
    padding: 3px;
    line-height: 1;
  }
  &.el-tag--success {
    border: unset;
  }
  &.el-tag--pending {
    border: unset;
    background-color: $tag-pending-bg-color;
    color: $tag-pending-color;
  }
  &.el-tag--warning {
    border: unset;
    background-color: $tag-warning-color;
    color: $warning-text;
  }
  &.el-tag--warning {
    border: unset;
    background-color: $tag-warning-color;
    color: $warning-text;
  }
  &.el-tag--disabled {
    border: unset;
    background-color: $gray200;
    color: $text-dark-color;
  }
  &.variant_clickable {
    cursor: pointer;
  }
}
</style>
