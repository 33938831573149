import Vue from "vue";

const state = Vue.observable({
  isActive: false,
});

const mutations = {
  SET_STATE(val) {
    state.isActive = val;
  },
};
export { state, mutations };
