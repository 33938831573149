const isChrome = () =>
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const filterEmptyValues = (obj) =>
  Object.entries(obj).reduce(
    (acc, [k, v]) => (v ? ((acc[k] = v), acc) : acc),
    {}
  );

const checkWindowMedia = (value, mobileFirst = true) => {
  let mediaQuery;
  if (mobileFirst) {
    mediaQuery = window.matchMedia(`(min-width: ${value}px)`);
  } else {
    mediaQuery = window.matchMedia(`(max-width: ${value}px)`);
  }
  if (mediaQuery.matches) return true;
  else return false;
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export { isChrome, filterEmptyValues, checkWindowMedia, formatBytes };
