import BaseButton from "@/components/ui/BaseButton.vue";
import BaseField from "@/components/ui/BaseField.vue";
import BaseSearchField from "@/components/ui/BaseSearchField.vue";
import BaseCheckBox from "@/components/ui/BaseCheckBox.vue";
import BaseCard from "@/components/ui/card/BaseCard.vue";
import BaseCardContent from "@/components/ui/card/BaseCardContent.vue";
import BaseTitle from "@/components/ui/title/BaseTitle.vue";
import BaseText from "@/components/ui/title/BaseText";
import BaseLabel from "@/components/ui/BaseLabel.vue";
import BaseLogo from "@/components/ui/BaseLogo.vue";
import StaticBaseLogo from "@/components/ui/StaticBaseLogo.vue";
import BaseIconCircle from "@/components/ui/BaseIconCircle.vue";
import SvgIcon from "@/components/ui/SvgIcon";
import UiToggler from "@/components/ui/UiToggler";
import UIClickOutside from "@/components/ui/UIClickOutside";
import BaseDivider from "@/components/ui/BaseDivider.vue";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import BaseSelect from "@/components/ui/BaseSelect";
import BaseTable from "@/components/ui/BaseTable";
import UploadFile from "@/components/ui/UploadFile";
import UiLoader from "@/components/ui/UiLoader";
import AppModelCard from "@/components/ui/AppModelCard";
import HelperLink from "@/components/ui/HelperLink.vue";
import UiLightBox from "@/components/ui/UiLightBox.vue";
import BaseFilterableList from "@/components/renderless/BaseFilterableList";
import BasePopoverMenu from "@/components/ui/BasePopoverMenu.vue";
import BaseDatePicker from "@/components/ui/BaseDatePicker.vue";

import BaseControls from "@/components/ui/BaseControls";

const COMPONENTS = {
  ["app-button"]: BaseButton,
  ["app-field"]: BaseField,
  ["base-search-field"]: BaseSearchField,
  ["app-checkbox"]: BaseCheckBox,
  ["app-card"]: BaseCard,
  ["app-title"]: BaseTitle,
  ["app-card-content"]: BaseCardContent,
  ["app-divider"]: BaseDivider,
  ["app-select"]: BaseSelect,
  ["app-date-picker"]: BaseDatePicker,
  ["base-table"]: BaseTable,
  ["app-logo"]: BaseLogo,
  ["static-logo"]: StaticBaseLogo,
  ["base-icon-circle"]: BaseIconCircle,
  ["app-label"]: BaseLabel,
  ["svg-icon"]: SvgIcon,
  ["base-text"]: BaseText,
  ["ui-toggler"]: UiToggler,
  ["user-avatar"]: UserAvatar,
  ["ui-click-outside"]: UIClickOutside,
  ["app-upload"]: UploadFile,
  ["app-loader"]: UiLoader,
  ["app-model-card"]: AppModelCard,
  ["app-helper-link"]: HelperLink,
  ["app-light-box"]: UiLightBox,
  ["base-filterable-list"]: BaseFilterableList,
  ["app-popover-menu"]: BasePopoverMenu,
};

export default function defineGlobalComponents(app) {
  for (let [name, component] of Object.entries(COMPONENTS)) {
    app.component(name, component);
  }
  BaseControls(app);
}
