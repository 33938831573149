import ROUTE_NAME from "@/router/router.const";
import OrderLayout from "@/layouts/OrderLayout.vue";
export default {
  path: "/order/:order",
  component: OrderLayout,
  children: [
    {
      path: "",
      name: ROUTE_NAME.ORDER_START,
      component: () => import("@/modules/order/OrderStart.page.vue"),
    },
    {
      path: "upload_files",
      name: ROUTE_NAME.ORDER_UPLOAD_FILES,
      component: () => import("@/modules/order/OrderUploadFiles.page.vue"),
    },
    {
      path: "order-configuration",
      name: ROUTE_NAME.ORDER_CONFIGURE,
      component: () => import("@/modules/order/OrderConfigure.page.vue"),
    },
    {
      path: "order-models",
      name: ROUTE_NAME.ORDER_MODLES,
      component: () => import("@/modules/order/OrderModelSelection.page.vue"),
    },
    {
      path: "checkout",
      name: ROUTE_NAME.ORDER_CHECKOUT,
      component: () => import("@/modules/order/OrderCheckout.page.vue"),
    },
    {
      path: "create_look",
      name: ROUTE_NAME.ORDER_CREATE_LOOK,
      component: () => import("@/modules/order/OrderCreateLook.page.vue"),
    },
    {
      path: "finish",
      name: ROUTE_NAME.ORDER_FINISH,
      component: () => import("@/modules/order/OrderFinish.page.vue"),
    },
    {
      path: "order-invoice",
      name: ROUTE_NAME.ORDER_INVOICE,
      component: () => import("@/modules/order/OrderInvoice.page"),
    },
    {
      path: "order-thank-you",
      name: ROUTE_NAME.ORDER_THANK,
      component: () => import("@/modules/order/OrderThankYouText.page.vue"),
    },
  ],
};
