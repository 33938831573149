<script>
export default {
  functional: true,
  render(h, context) {
    let classes = ["card", context.data.staticClass];
    return h("div", { class: classes }, context.children);
  },
};
</script>

<style lang="scss">
.card {
  position: relative;
  max-width: 100%;
  border-radius: 16px;
  background-color: white;
  box-shadow: $card-shadow;
  color: $text;
  font-size: 14px;
  &_radius_sm {
    border-radius: $--border-radius-base;
  }
}
</style>
