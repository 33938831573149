<template>
  <div class="page-content">
    <div class="mobile-container">
      <app-card class="full-card">
        <app-card-content large-spacing>
          <div class="mobile-layout">
            <div class="mobile-layout__logo">
              <static-logo></static-logo>
            </div>
            <div class="mobile-layout__device">
              <base-icon-circle>
                <template #icon>
                  <img src="~@/assets/img/laptop.svg" />
                </template>
              </base-icon-circle>
            </div>
            <base-text size="lg" appearance="bold"
              >Desktops &#38; Laptops only</base-text
            >
            <div class="mobile-layout__info">
              <p>
                The Lalaland platform is only available on desktops & laptops.
                Please log in using a different device.
              </p>
            </div>
          </div>
        </app-card-content>
      </app-card>
    </div>
  </div>
</template>

<script>
import { LAPTOP_WIDTH } from "@/util/const";
import { checkWindowMedia } from "@/util/general";
import { debounce as _debounce } from "@/api/utils";

export default {
  mounted() {
    window.addEventListener("resize", this.debounceAction);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.debounceAction);
    });
  },
  methods: {
    checkMobile() {
      if (!checkWindowMedia(LAPTOP_WIDTH)) {
        return;
      } else {
        this.$router.push({ path: this.$route.query.pageFrom });
      }
    },
    debounceAction: _debounce(function () {
      this.checkMobile();
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.mobile-container {
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 12px;
  background-color: $background;
  .full-card {
    flex: auto;
  }
}
.mobile-layout {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  &__ {
    &device {
      margin: 60px 0 38px 0;
    }
    &info {
      margin-top: 24px;
      color: $text-dark-color;
      font-size: $size-sm;
    }
    &btn {
      margin: 16px 0;
      width: 310px;
    }
  }
}
</style>
