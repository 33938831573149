import authRouter from "@/modules/auth/core/auth.router";
import baseRouter from "@/modules/dashboard/core/base.router";
import OrderRouter from "@/modules/order/core/order.router";
import adminRouter from "@/modules/auth-admin/core/admin.router";
import stuffRouter from "@/modules/stuff/core/stuff.router";
import mobileRouter from "@/modules/stuff/core/mobile.router";
import loginAsUser from "@/modules/stuff/core/loginAsUser.router";
import inviteRouter from "@/modules/invite/core";
import noPath from "./no-path.router";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  authRouter,
  baseRouter,
  OrderRouter,
  adminRouter,
  stuffRouter,
  noPath,
  mobileRouter,
  inviteRouter,
  loginAsUser,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
