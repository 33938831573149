<template>
  <el-button
    v-bind="{ ...$props, ...$attrs }"
    :disabled="disabled"
    :class="classes"
    outlined
  >
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: "AppBtn",
  props: {
    type: {
      type: String,
      validator: function (value) {
        return [
          "default",
          "primary",
          "secondary",
          "success",
          "danger",
          "info",
          "warning",
        ].includes(value);
      },
    },

    size: {
      type: String,
      default: "medium",
    },
    outlined: Boolean,
    expanded: Boolean,
    nativeType: String,
    plain: Boolean,
    disabled: Boolean,
    loading: Boolean,
    textWrap: Boolean,
    disabledGrayVariant: Boolean,
    notClickable: Boolean,
    notActive: Boolean,
    variantUppcase: Boolean,
  },

  computed: {
    classes() {
      let classes = ["button"];
      if (this.expanded) classes.push("button_expanded");
      if (this.textWrap) classes.push("button_text-wrap");
      if (this.disabledGrayVariant) classes.push("button_disable-variant-grey");
      if (this.notClickable) classes.push("events-none");
      if (this.notActive) classes.push("not-active");
      if (this.variantUppcase) classes.push("uppercase");
      return classes;
    },
  },
};
</script>

<style lang="scss">
.button {
  border-radius: $--border-radius-base;
  white-space: normal;
  font-weight: 500;
  &_expanded {
    width: 100%;
  }
  &_text-wrap {
    white-space: normal !important;
  }
  &.el-button {
    &-- {
      &default {
        color: $primary;
        border-color: $primary;
      }
      &sm {
        padding: 8px 12px;
      }
      &secondary {
        background-color: $cyan650;
        color: $white;
        &:focus,
        &:hover {
          color: $white;
          border-color: $cyan650;
          background-color: rgba($cyan650, 0.4);
        }
      }
    }
    &.is-disabled {
      &.button_disable-variant-grey {
        background-color: $gray200;
        border-color: $gray200;
        color: $text-dark-color;
        box-shadow: none;
      }
      &.el-button--default {
        border-color: $default-disabled-state-button;
        color: $default-disabled-state-button;
      }
    }
  }
  &.is-medium {
    height: 40px;
  }
  &.is-large {
    height: 48px;
  }
  &.offset-horizontal_md {
    padding: 12px 36px;
  }
  &.tab-button {
    &.el-button--default {
      color: $primary;
    }
  }
  &.events-none {
    pointer-events: none;
  }
  &.not-active {
    opacity: 0.3;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &__ {
    &row {
      display: flex;
      align-items: center;
    }
    &text {
      &_offset_left {
        margin-left: 14px;
      }
      &_offset_left_xs {
        margin-left: 8px;
      }
      &_offset_right_xs {
        margin-right: 8px;
      }
    }
    &icon {
      display: inline-flex;
    }
  }
}
</style>
