import LookSvc from "./look.service";

export default {
  namespaced: true,

  state: () => ({
    looks: [],
  }),

  mutations: {
    updateLooks(state, payload) {
      state.looks = payload;
    },

    addLook(state, payload) {
      state.looks.push(payload);
    },

    changeLookResult(state, payload) {
      const changedLookIndex = state.looks.findIndex(
        (look) => look.id === payload.itemId
      );
      const changedResultIndex = state.looks[
        changedLookIndex
      ].results.findIndex((result) => result.id === payload.result.id);
      state.looks[changedLookIndex].results[changedResultIndex].status =
        payload.result.status;
    },

    removeLook(state, lookID) {
      let index = state.looks.findIndex((look) => look.id === lookID);
      state.looks.splice(index, 1);
    },

    changeModelName(state, { name, index }) {
      state.looks[index].selected_model.name = name;
    },
  },

  actions: {
    async loadLooks({ commit }, { api, order }) {
      let res = await new LookSvc(api).looks(order);
      commit("updateLooks", res);
      return res;
    },

    async removeLook({ commit }, { api, id }) {
      await new LookSvc(api).remove(id);
      commit("removeLook", id);
    },

    clearLooks({ commit }) {
      commit("updateLooks", []);
    },

    updateLookStatus({ commit }, payload) {
      commit("changeLookResult", payload);
    },
  },
};
