<template>
  <div class="app-field" :class="className">
    <div class="app-field__label-row">
      <div v-if="label" class="app-field__label">
        <slot name="label">
          {{ label }}
          <span class="app-field__tip">{{ tip }} </span>
        </slot>
      </div>
      <div class="app-field__prepend">
        <slot name="prepend"></slot>
      </div>
    </div>
    <el-select
      v-bind="{ ...$props, ...$attrs }"
      :class="{
        'app-input_error': error && error.length,
        'app-input_transparent': transparent,
      }"
      @change="$emit('input', $event)"
      :autocomplete="autocompleteValue"
      :disabled="isDisabled"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <div class="app-field__error" v-if="!hideError">
      <div class="control-hint" v-show="error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { isChrome } from "@/util/general";

export default {
  props: {
    value: {
      required: true,
    },
    options: Array,
    label: String,
    placeholder: String,
    tip: String,
    passwordReveal: Boolean,
    iconPack: String,
    nativeType: String,
    name: String,
    error: String,
    className: String,
    hideError: Boolean,
    transparent: Boolean,
    multiple: Boolean,
    isDisabled: Boolean,
  },
  computed: {
    autocompleteValue() {
      return isChrome() ? "disabled" : "off";
    },
  },
};
</script>

<style lang="scss">
.el-select {
  &_variant_transparent {
    border: 0;
  }
  &_variant_white {
    background-color: $white;
  }
  &_shadow_none {
    box-shadow: none;
  }
  &_radius_base {
    .el-input {
      &__ {
        &inner {
          border-radius: $--border-radius-base;
        }
      }
    }
  }
  &_size_sm {
    .el-input {
      font-size: 14px;
      .el-select__caret {
        font-size: 10px !important;
      }
      &__ {
        &inner {
          padding-right: 10px !important;
          padding-left: 8px;
        }
      }
    }
  }
}
</style>
