<template>
  <div id="app">
    <div
      v-if="overlayIsVisible"
      class="ui-overlay"
      @click="collapseOverlay"
    ></div>
    <error-handler>
      <router-view />
    </error-handler>
  </div>
</template>

<script>
import ROUTE_NAME from "@/router/router.const";
import ErrorHandler from "@/components/errors/ErrorHandler.vue";
import initApi from "./api/initApi";
import { overlayState } from "@/observable-store";

import { LAPTOP_WIDTH } from "@/util/const";
import { checkWindowMedia } from "@/util/general";
import { debounce as _debounce } from "@/api/utils";

const { state: overlayStore, mutations: overlayMutations } = overlayState;

let api = initApi;
export default {
  data: () => ({
    testData: "",
  }),

  components: {
    ErrorHandler,
  },

  provide() {
    return {
      ROUTE_NAME: ROUTE_NAME,
      api: api,
    };
  },
  created() {
    this.checkMobile();
  },
  mounted() {
    window.addEventListener("resize", this.debounceAction);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.debounceAction);
    });
    this.injectTrengoScript();
  },
  computed: {
    overlayIsVisible() {
      return overlayStore.isActive;
    },
    enableMobileView() {
      return location.pathname.includes("auth");
    },
  },
  methods: {
    collapseOverlay() {
      overlayMutations.SET_STATE(false);
    },
    checkMobile() {
      if (checkWindowMedia(LAPTOP_WIDTH) || this.enableMobileView) {
        return;
      } else {
        if (ROUTE_NAME.MOBILE_LAYOUT !== this.$route.name) {
          this.$router.push({
            name: ROUTE_NAME.MOBILE_LAYOUT,
            query: {
              pageFrom: this.$router.resolve({ name: this.$route.name }).route
                .path,
            },
          });
        } else {
          return;
        }
      }
    },
    async injectTrengoScript() {
      window.Trengo = window.Trengo || {};
      window.Trengo.key = "2uskQSUcQv1rGIc";
      (function (d, script) {
        script = d.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://static.widget.trengo.eu/embed.js";
        d.getElementsByTagName("head")[0].appendChild(script);
      })(document);
    },
    debounceAction: _debounce(function () {
      this.checkMobile();
    }, 1000),
  },
};
</script>

<style lang="scss">
.container {
  margin: 0 auto;
  padding-right: 12px;
  padding-left: 12px;
  max-width: 1080px;
  @include media(">=desktop") {
    padding-right: $container-inner;
    padding-left: $container-inner;
  }
}
#app {
  color: #2c3e50;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    color: #2c3e50;
    font-weight: bold;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
