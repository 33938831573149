<template>
  <section class="auth-login auth-login_variant_admin">
    <app-card class="has-text-left">
      <app-card-content large-spacing>
        <app-title without-spacing tag="h1" class="auth-login__title"
          >Sign in - Admin
        </app-title>
        <ValidationObserver v-slot="{ invalid }" ref="formValidator">
          <form
            class="auth-form auth-login__form"
            @submit.prevent="login(invalid)"
          >
            <base-field-v
              v-model="formData.email"
              label="Email Address"
              placeholder="You@email.com"
              type="email"
              message="This email is invalid"
              name="E-mail"
              rules="required|email"
              :debounce="500"
            ></base-field-v>
            <base-field-v
              name="password"
              rules="required|min:8"
              :debounce="500"
              v-model="formData.password"
              placeholder="Enter a password"
              native-type="password"
              label="Password"
              tip="Use at least 8 characters"
              show-password
            >
            </base-field-v>

            <app-button
              type="primary"
              expanded
              :disabled="invalid"
              native-type="submit"
              class="auth-login__submit"
              >Sign in</app-button
            >
          </form>
        </ValidationObserver>
      </app-card-content>
    </app-card>
  </section>
</template>

<script>
import formErrorsMixin from "@/mixins/formErrors.mixin";
export default {
  mixins: [formErrorsMixin],
  inject: ["api", "ROUTE_NAME"],
  data: () => ({
    formData: {
      email: "",
      password: "",
    },
  }),

  methods: {
    async login(invalid) {
      try {
        if (invalid) "Please fill email and password";
        await this.api.updateTokensByCredentials(this.formData);
        this.$router.push({
          name: this.ROUTE_NAME.ADMIN_OVERVIEW,
          params: { layout: this.ROUTE_NAME.ADMIN_PANEL },
        });
      } catch (error) {
        this.processError(error, {
          validator: this.$refs.formValidator.setErrors,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-login {
  margin: 0 auto;
  &_variant_admin {
    max-width: unset;
    width: 440px;
  }
  & &__title.title {
    margin-bottom: 40px;
  }
  &__divider {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &__submit {
    margin-top: 0 !important;
  }
}
</style>
