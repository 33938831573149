<template>
  <div class="app-field" :class="className">
    <div v-if="label" class="app-field__label">
      <slot name="label">
        {{ label }}
        <span class="app-field__tip">{{ tip }} </span>
      </slot>
    </div>
    <el-date-picker
      class="app-date-picker"
      v-bind="$props"
      @input="$emit('input', $event)"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    className: String,
    placeholder: String,
    label: String,
    tip: String,
    type: String,
    rangeSeparator: String,
    startPlaceholder: String,
    endPlaceholder: String,
    valueFormat: String,
  },
};
</script>

<style lang="scss">
.el-date-editor {
  width: 100% !important;
  .el-range-input {
    flex: auto;
  }
  &.el-input__inner {
    background-color: transparent;
  }
}

</style>
