import BaseField from "@/components/ui/BaseField.vue";
import BaseSelect from "@/components/ui/BaseSelect.vue";
import BaseCheckBox from "@/components/ui/BaseCheckBox.vue";
import ValidationErrorName from "@/components/ui/ValidationErrorName.vue";
import { withValidation } from "vee-validate";

const BaseFieldV = withValidation(BaseField, ({ required, errors }) => ({
  isRequired: required,
  error: errors[0],
}));

const BaseSelectV = withValidation(BaseSelect, ({ required, errors }) => ({
  isRequired: required,
  error: errors[0],
}));

const BaseCheckBoxV = withValidation(BaseCheckBox, ({ required, errors }) => ({
  isRequired: required,
  error: errors[0],
}));
const ValidationErrorField = withValidation(
  ValidationErrorName,
  ({ errors }) => ({
    error: errors[0],
  })
);

export default function (Vue) {
  Vue.component("BaseFieldV", BaseFieldV);
  Vue.component("BaseSelectV", BaseSelectV);
  Vue.component("BaseCheckBoxV", BaseCheckBoxV);
  Vue.component("ValidationErrorField", ValidationErrorField);
}
