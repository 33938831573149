<template>
  <div class="main-sidebar">
    <div class="main-sidebar__body">
      <div
        v-for="(link, idx) in sidebarLinks"
        :key="idx"
        class="main-sidebar__link-item"
      >
        <router-link
          class="rout-link"
          active-class="is-active-rout"
          :to="link.route ? { name: link.route } : link.path"
          @click.native="$emit('collapse')"
        >
          <div class="rout-link__icon">
            <component class="hero-icon" :is="link.icon"></component>
          </div>
          <span class="rout-link__label">{{ link.label }}</span>
        </router-link>
      </div>
      <div class="main-sidebar__link-item">
        <a
          class="rout-link"
          href="https://185665-lalaland.trengohelp.com/en_GB"
          target="_blank"
        >
          <div class="rout-link__icon">
            <component class="hero-icon" :is="'AcademicCapIcon'"></component>
          </div>
          <span class="rout-link__label">Help Center</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ROUTE_NAME from "@/router/router.const";

export default {
  props: {
    isAdmin: Boolean,
  },
  computed: {
    sidebarLinks() {
      return this.isAdmin
        ? this.$options.routLinks.adminLinks
        : this.$options.routLinks.mainLinks;
    },
  },
  routLinks: {
    mainLinks: [
      { route: ROUTE_NAME.OVERVIEW, label: "Overview", icon: "HomeIcon" },
      {
        route: ROUTE_NAME.DASHOBARD_PROJECTS,
        label: "Renders",
        icon: "CloudDownloadIcon",
      },
      {
        route: ROUTE_NAME.DASHOBARD_INVOICES,
        label: "Invoices",
        icon: "DocumentTextIcon",
      },
      {
        route: ROUTE_NAME.DASHOBARD_SUBSCRIPTION,
        label: "Subscription",
        icon: "RefreshIcon",
      },
    ],
    adminLinks: [
      { route: ROUTE_NAME.ADMIN_OVERVIEW, label: "Overview", icon: "HomeIcon" },
      {
        route: ROUTE_NAME.ADMIN_PROJECTS,
        label: "Projects",
        icon: "NewspaperIcon",
      },
    ],
  },
};
</script>

<style lang="scss">
$sidebar_bg: $gray800;
.main-sidebar {
  height: 100%;
  background-color: $sidebar_bg;

  @extend %d-flex;
  @extend %d-flex_direction_column;
  &__ {
    &body,
    &footer {
      padding: $space-2lg $space-lg;
    }
    &link-item {
      margin: 8px 0;
      text-align: left;
    }
  }
}
.rout-link {
  display: flex;
  align-items: center;
  padding: 14px;
  border: 2px solid transparent;
  border-radius: $--border-radius-base;
  color: $white;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  &.is-active-rout {
    background: $active-rout-bg;
  }
  &:hover {
    border-color: $active-rout-bg;
    color: $white;
  }
  &__ {
    &icon {
      font-size: 18px;

      @extend %element-inliner;
      @extend %element-inliner--inline;
    }
    &label {
      margin-left: 12px;
      font-size: $size-s;
    }
  }
}
</style>
