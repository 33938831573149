import { prefixAPI } from "@/api/utils";

const MODEL = "admin";

const ADMIN_ORDERS_URL = prefixAPI(`${MODEL}/order/orders/`);
const SUBSCRIPTIONS_ORDERS_TOTAL = prefixAPI(
  `${MODEL}/subscriptions/payments/order_total/`
);
const ADMIN_ORDER_LOOKS = prefixAPI(`${MODEL}/order/looks/`);
const ADMIN_FEEDBACK_FINISH_URI = prefixAPI(`${MODEL}/order/feedback_rounds/`);
const ADMIN_FEEDBACK_RESULTS_URI = prefixAPI(
  `${MODEL}/order/result_feedbacks/`
);

export default class AdminSvc {
  constructor(api) {
    this.api = api;
  }
  async ordersList(params) {
    const response = await this.api.get(ADMIN_ORDERS_URL, { params });
    return response;
  }
  async ordersInfo() {
    const response = await this.api.get(SUBSCRIPTIONS_ORDERS_TOTAL);
    return response;
  }

  async order(id) {
    const response = await this.api.get(`${ADMIN_ORDERS_URL}${id}/`);
    return response;
  }

  async deleteOprder(id) {
    await this.api.delete(`${ADMIN_ORDERS_URL}${id}/`);
  }

  async upload(file, callbackProgress, roundId) {
    let formData = new FormData();
    formData.append("image", file);
    const response = await this.api.post(
      `${ADMIN_FEEDBACK_FINISH_URI}${roundId}/upload_results/`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data;" },
        onUploadProgress: function (progressEvent) {
          if (callbackProgress) callbackProgress(progressEvent);
        },
      }
    );
    return response;
  }

  async looks(order) {
    return await this.api.get(ADMIN_ORDER_LOOKS, {
      params: { order },
    });
  }

  async finshRoundAdmin(roundId) {
    return await this.api.get(`${ADMIN_FEEDBACK_FINISH_URI}${roundId}/finish/`);
  }

  async getResultFeedback(feedbackId) {
    return await this.api.get(`${ADMIN_FEEDBACK_RESULTS_URI}${feedbackId}/`);
  }
}
