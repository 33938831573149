<template functional>
  <div
    class="model-card"
    :class="[data.staticClass, data.class]"
    v-on="listeners"
  >
    <div v-if="$slots.control" class="model-card__control">
      <slot name="control"></slot>
    </div>
    <div class="model-card__body">
      <slot v-if="$slots.previewContent" name="previewContent"></slot>
      <img
        v-else-if="props.imgSrc"
        :src="props.imgSrc"
        alt=""
        class="model-card__preview"
        loading="lazy"
      />
      <div class="model-card__info">
        <slot name="title"></slot>
        <div class="model-card__info-row" v-if="$slots.default">
          <div class="model-card__cols model-card__cols_variant_name">
            <slot></slot>
          </div>
          <div
            v-if="$slots.info"
            class="model-card__cols model-card__cols_variant_info"
          >
            <slot name="info"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: String,
  },
};
</script>

<style lang="scss">
.model-card {
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 130%;
  border: 1px solid $border;
  border-radius: $--border-radius-base;
  background-color: $white;
  &.active {
    border-color: $selected;
  }
  &__ {
    &body {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &info {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      width: 100%;
      background-color: rgb(255 255 255 / 0.85);
      color: $primary-gray;
      text-align: center;
      font-size: $size-s;

      // backdrop-filter: blur(18px);
    }
    &preview {
      max-width: 100%;
      max-height: 100%;

      @extend %full-image;
    }
    &control {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 1;
    }
    &info-row {
      @extend %d-flex;
      @extend %d-flex_justify_between;
      @extend %d-flex_align_center;
    }
    &cols {
      &_variant_name {
        width: 45%;
        text-align: left;
        & > div,
        p,
        a {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      &_variant_info {
        width: 55%;
        text-align: right;
      }
    }
  }
}
</style>
