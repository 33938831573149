import { prefixAPI } from "@/api/utils";
const MODEL = "client/subscriptions/";

const SUBSCRIPTIONS_PLANS_URL = prefixAPI(`${MODEL}subscription-plans/`);
const PAYMENT_PERIODS_URL = prefixAPI(`${MODEL}payment-periods/`);
const UNSUBSCRIBE_PLAN_URL = prefixAPI(`${MODEL}payments/cancel_subscription/`);
const SUBSCRIBE_ADYEN_PLAN_URL = prefixAPI(
  `${MODEL}adyen/set_subscription_plan/`
);
const UPDATE_PAYMENT_METHOD_URL = prefixAPI(
  `${MODEL}adyen/update_payment_method/`
);
const UPDATE_PAYMENT_METHODS_URL = prefixAPI(`${MODEL}adyen/payment_methods/`);
const SUBSCRIPTION_AVAILABLE_LOOKS = prefixAPI(
  `${MODEL}user-looks-history/available_looks/`
);
const HANDLE_PAYMENT_RESULT_URL = prefixAPI(
  `${MODEL}adyen/check_redirect_result/`
);
const HANDLE_PAYMENT_ATTEMPT_URL = (payment_attempt_id) =>
  prefixAPI(`${MODEL}payments/${payment_attempt_id}/attempt/`);
const UPDATE_HANDLE_PAYMENT_RESULT_URL = prefixAPI(
  `${MODEL}adyen/check_payment_method_redirect_result/`
);
const CALCULATE_PRICE = prefixAPI(
  `${MODEL}subscription-plans/calculate_price/`
);

export default class SubcrptnSvc {
  constructor(api) {
    this.api = api;
  }

  async subscriptions() {
    return await this.api.get(SUBSCRIPTIONS_PLANS_URL);
  }

  async setSubscriptionAdyen(data) {
    return await this.api.post(SUBSCRIBE_ADYEN_PLAN_URL, data);
  }

  async unsubscribe() {
    return await this.api.delete(UNSUBSCRIBE_PLAN_URL);
  }

  async available() {
    return await this.api.get(SUBSCRIPTION_AVAILABLE_LOOKS);
  }

  async payemntPeriods() {
    return await this.api.get(PAYMENT_PERIODS_URL);
  }

  async updatePaymentMethods(data) {
    return await this.api.post(UPDATE_PAYMENT_METHOD_URL, data);
  }

  async adyenPaymentMethods() {
    return await this.api.get(UPDATE_PAYMENT_METHODS_URL);
  }

  async handlePaymentAttempt(payment_attempt_id) {
    return await this.api.get(HANDLE_PAYMENT_ATTEMPT_URL(payment_attempt_id));
  }

  async handleRedirectResult(data) {
    return await this.api.post(HANDLE_PAYMENT_RESULT_URL, data);
  }

  async updateHandleRedirectResult(data) {
    return await this.api.post(UPDATE_HANDLE_PAYMENT_RESULT_URL, data);
  }
  async getCalculatedPrice(data) {
    return await this.api.post(CALCULATE_PRICE, data);
  }
}
