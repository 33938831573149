<template>
  <component :is="layout"></component>
</template>

<script>
import Dashboard from "@/layouts/DashboardLayout";
import DashboardAdmin from "@/layouts/DashboardAdmin.layout";
import ROUTE_NAME from "@/router/router.const";

const layoutsConfig = {
  [ROUTE_NAME.CLIENT_PANEL]: "Dashboard",
  [ROUTE_NAME.ADMIN_PANEL]: "DashboardAdmin",
};

import store from "@/store/index";

import { LAPTOP_WIDTH } from "@/util/const";
import { checkWindowMedia } from "@/util/general";

const hasAccess = (userStuff, routDestination, adminRout) => {
  try {
    return !userStuff && routDestination === adminRout ? false : true;
  } catch (error) {
    throw new Error(error);
  }
};

const validLayout = (layout) => {
  return ROUTE_NAME.CLIENT_PANEL === layout || ROUTE_NAME.ADMIN_PANEL === layout
    ? true
    : false;
};

export default {
  name: "BaseLayout",
  computed: {
    layout() {
      const keyLayout = this.$route.params.layout;
      return layoutsConfig[keyLayout];
    },
  },
  created() {
    this.checkMobile();
  },
  async beforeRouteEnter(to, from, next) {
    if (!validLayout(to.params.layout))
      next({ name: ROUTE_NAME.NOT_FOUND_GENERAL });
    try {
      await store.dispatch("user/loadUser");
      await store.dispatch("user/updateNotifications");
      if (
        hasAccess(
          store.state.user.user.is_staff,
          to.params.layout,
          ROUTE_NAME.ADMIN_PANEL
        )
      ) {
        next();
      } else {
        next({
          name: store.state.user.user.is_staff
            ? ROUTE_NAME.ADMIN_LOGIN
            : ROUTE_NAME.LOGIN,
        });
      }
    } catch (error) {
      if (error.code === 401) {
        next({
          name: store.state.user.user.is_staff
            ? ROUTE_NAME.ADMIN_LOGIN
            : ROUTE_NAME.LOGIN,
        });
      }
    }
  },
  mounted() {
    this.hideRecaptchaBadge();
  },
  methods: {
    checkMobile() {
      if (checkWindowMedia(LAPTOP_WIDTH)) {
        return;
      } else {
        if (ROUTE_NAME.MOBILE_LAYOUT !== this.$route.name) {
          this.$router.push({
            name: ROUTE_NAME.MOBILE_LAYOUT,
            query: {
              pageFrom: this.$router.resolve({ name: this.$route.name }).route
                .path,
            },
          });
        } else {
          return;
        }
      }
    },
    hideRecaptchaBadge() {
      const target = document.getElementsByClassName("grecaptcha-badge")[0];
      if (!target) return;
      target.style.visibility = "hidden";
    },
  },
  components: {
    Dashboard,
    DashboardAdmin,
  },
};
</script>
