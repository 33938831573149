import { prefixAPI } from "@/api/utils";
const LOOK = prefixAPI("client/order/looks/");
const FINISH_ROUND_URI = prefixAPI("client/order/feedback_rounds/");
const FEEDBACK_MESSAGE_URI = prefixAPI(
  "client/order/feedback_messages/bulk_create/"
);

const UPLOAD_FEEDBACK_IMAGE_URI = prefixAPI(
  "client/order/feedback_messages/bulk_attach_images/"
);
const UPDATE_LOOK_GARMENT = prefixAPI("client/order/look_garments/");

export default class LookSvc {
  constructor(api) {
    this.api = api;
  }

  async create(formData) {
    let res = await this.api.post(LOOK, {
      ...formData,
      order: +formData.order,
      model_template: formData.model_template.id,
      shoe: formData?.shoe?.garment.id ?? "",
      garments: formData.garments.map((item) => {
        return {
          garment: item.garment.id,
          garment_fit: item.garment_fit,
          garment_lengths: item.garment_lengths,
          style_note: item.style_note,
        };
      }),
    });
    return res;
  }

  async update(formData, id) {
    return await this.api.patch(`${LOOK}${id}/`, formData);
  }

  looks(order) {
    return this.api.get(LOOK, {
      params: { order },
    });
  }

  updateLookGarment(data, garmentId) {
    return this.api.patch(`${UPDATE_LOOK_GARMENT}${garmentId}/`, data);
  }

  remove(id) {
    return this.api.delete(LOOK + id + "/");
  }

  attachFileToMsg(formData) {
    return this.api.post(UPLOAD_FEEDBACK_IMAGE_URI, formData, {
      headers: { "Content-Type": "multipart/form-data;" },
    });
  }

  startRound(roundId) {
    return this.api.get(`${FINISH_ROUND_URI}${roundId}/start/`);
  }

  finishRound(roundId) {
    return this.api.get(`${FINISH_ROUND_URI}${roundId}/finish/`);
  }

  getLookPreview(lookId) {
    return this.api.get(`${LOOK}${lookId}/`);
  }

  sendTextMessageRound(data) {
    return this.api.post(FEEDBACK_MESSAGE_URI, data);
  }
}
