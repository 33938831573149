<template>
  <div class="auth-wrapper">
    <div class="container-fluid">
      <div class="auth-layout">
        <header class="auth-header">
          <div class="auth-header__block">
            <portal-target name="auth-header-left-side"> </portal-target>
          </div>
          <div class="auth-header__logo">
            <div class="auth-header__logo-col">
              <div class="auth-logo">
                <app-logo />
              </div>
            </div>
          </div>
          <div class="auth-header__block">
            <portal-target name="auth-header-right-side"> </portal-target>
          </div>
        </header>
        <main class="auth-layout__content container">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["api"],
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  background-color: $background;
  &_variant_admin {
    background-color: $admin-layout-bg;
  }
}

.auth-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__content {
    display: flex;
    flex-grow: 1;
    width: 100%;
    @include media(">=desktop") {
      align-items: center;
    }
    section {
      padding-top: 40px;
    }
  }
  &__admin-nav {
    padding-top: $space-2xl;
    text-align: right;
  }
}

.auth-form {
  margin-top: 24px;
  & button[type="submit"] {
    margin-top: 14px;
  }
}

.auth-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
  gap: 14px;
  width: 100%;
  &__logo {
    text-align: center;
    flex: 1 0 100%;
    @include media(">=tablet") {
      flex: 1 0 auto;
    }
  }
  &__logo-col {
    display: inline-flex;
    align-items: flex-end;
  }
  &__block {
    @include media(">=desktop") {
      min-width: 400px;
    }
    &:first-child {
      order: 1;
      @include media(">=tablet") {
        order: unset;
      }
      @include media(">=desktop") {
        padding-left: 44px;
      }
      .vue-portal-target {
        text-align: left;
      }
    }
    &:last-child {
      order: 2;
      @include media(">=tablet") {
        order: unset;
      }
      @include media(">=desktop") {
        padding-right: 44px;
      }
      .vue-portal-target {
        text-align: right;
      }
    }
  }
  &__sub-title {
    margin-top: $space-2md;
  }
}

.auth-tip {
  color: $field-tip;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  &_footer {
    margin-top: 40px;
  }
  &_max-width {
    margin-right: auto;
    margin-left: auto;
    max-width: 440px;
  }
}

.auth-text {
  margin-top: 16px;
  margin-bottom: 0;
  color: $field-tip;
  font-weight: 500;
  line-height: 19px;
}

.auth-link-with-icon {
  display: inline-flex;
  align-items: center;
  svg {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    font-size: 15px;
  }
}

.auth-text-with-btn {
  .app-link {
    margin-left: 16px;
  }
}

.auth-logo {
  display: block;
  cursor: pointer;
  width: 136px;
}
</style>
