import {prefixAPI} from "@/api/utils";

export const ORDERS = prefixAPI(`client/order/orders/`);
const CONFIRM_RESULT = prefixAPI("client/order/results/");
const ORDER_SET_URL = prefixAPI("client/order/sets/");
const ORDER_RESULT_FEEDBACK_URL = prefixAPI("client/order/result_feedbacks/");
const ORDER_UPDATE_RESULTS_STATUS_URI = prefixAPI(
  "client/order/result_feedbacks/bulk_status_update/"
);
const FEEDBACK_MESSAGE_URI = prefixAPI(
  "client/order/feedback_messages/bulk_create/"
);
const EDIT_FEEDBACK_MESSAGE_URI = prefixAPI("client/order/feedback_messages/");
const DELETE_FEEDBACK_IMAGE_URI = prefixAPI(
  "client/order/feedback_message_images/"
);

const ORDER_PLACE = prefixAPI(`client/order/orders/`);

export default class OrderSvc {
  constructor(api) {
    this.api = api;
  }

  async create(formData) {
    let res = await this.api.post(ORDERS, {
      ...formData,
    });
    return res;
  }

  async changeOrderName(id, name) {
    return await this.api.post(`${ORDERS}${id}/change_name/`, {
      name
    });
  }

  async order(id) {
    let res = await this.api.get(`${ORDERS}${id}/`);
    return res;
  }

  async ordersList(data) {
    let res = await this.api.get(ORDERS, { params: data });
    return res;
  }

  async checkoutInfo(order) {
    let res = await this.api.get(`${ORDERS}${order}/checkout_info/`);
    return res;
  }

  async deleteOprder(id) {
    await this.api.delete(`${ORDERS}${id}/`);
  }

  async approveResult({ id, data }) {
    const response = await this.api.patch(`${CONFIRM_RESULT}${id}/`, {
      ...data,
    });
    return response;
  }

  async createSet(data) {
    return this.api.post(`${ORDER_SET_URL}`, {
      ...data,
    });
  }

  async setsList(data) {
    return await this.api.get(ORDER_SET_URL, { params: data });
  }
  async deleteGarmentSet(id) {
    return await this.api.delete(`${ORDER_SET_URL}${id}/`);
  }
  async getResultFeedback(feedbackId) {
    return await this.api.get(`${ORDER_RESULT_FEEDBACK_URL}${feedbackId}/`);
  }
  updateLookStatus(data, feedbackId) {
    return this.api.patch(`${ORDER_RESULT_FEEDBACK_URL}${feedbackId}/`, data);
  }
  async updateLookStatusBulk(data) {
    return await this.api.post(ORDER_UPDATE_RESULTS_STATUS_URI, data);
  }
  sendTextMessageRound(data) {
    return this.api.post(FEEDBACK_MESSAGE_URI, data);
  }
  editFeedbackMessage(data, messsageId) {
    return this.api.patch(`${EDIT_FEEDBACK_MESSAGE_URI}${messsageId}/`, data);
  }
  deleteFeedbackImage(imageId) {
    return this.api.delete(`${DELETE_FEEDBACK_IMAGE_URI}${imageId}/`);
  }
  deleteTextMessageRound(messsageId) {
    return this.api.delete(`${EDIT_FEEDBACK_MESSAGE_URI}${messsageId}/`);
  }
  orderPlace(idOrder) {
    return this.api.get(`${ORDER_PLACE}${idOrder}/place/`);
  }
}
