import { prefixAPI } from "@/api/utils";
const MODEL = "client/notifications/messages/";

const NOTIFIACTIONS_LIST_URL = prefixAPI(MODEL);
const GET_HELP = prefixAPI("client/notifications/messages/get_help/");

export default class NotifySvc {
  constructor(api) {
    this.api = api;
  }

  async notifyList(data) {
    return await this.api.get(NOTIFIACTIONS_LIST_URL, { params: data });
  }

  async deleteItem(id) {
    return await this.api.delete(`${NOTIFIACTIONS_LIST_URL}${id}/`);
  }

  async markReaded(id) {
    return await this.api.patch(`${NOTIFIACTIONS_LIST_URL}${id}/mark_as_read/`);
  }

  async getHelp(formData) {
    const res = await this.api.post(GET_HELP, formData);
    return res;
  }
}
