<template>
  <div>
    <slot v-if="!visibleDefault" :showimg="showSingle">
      <button v-if="visibleDefaultSlot" @click="showSingle">
        Show single picture.
      </button>
    </slot>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="currentImg"
      :index="index"
      @hide="handleHide"
      v-bind="pluginOptions"
      ref="lightBox"
    >
    </vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
const defaullOptions = {};
export default {
  props: {
    imgSrc: String,
    visibleDefault: Boolean,
    options: Object,
    visibleDefaultSlot: Boolean,
    zoomOutDefault: Number,
  },
  data() {
    return {
      visible: false,
      index: 0, // default: 0
    };
  },
  computed: {
    currentImg() {
      return this.imgSrc;
    },
    pluginOptions() {
      return { ...defaullOptions, ...this.options };
    },
  },
  methods: {
    showSingle() {
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  created() {
    if (this.visibleDefault) this.show();
  },
  mounted() {
    if (this.zoomOutDefault) {
      for (let i = 0; this.zoomOutDefault > i; i++) {
        this.$refs["lightBox"].zoomOut();
      }
    }
  },
  components: {
    VueEasyLightbox,
  },
};
</script>
