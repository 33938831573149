function errorParser(data) {
  if (!Object.keys(data).length) return [];
  return Object.keys(data).reduce((acc, key) => {
    acc = acc || {};
    acc[key] = [data[key].join("/")];
    return acc;
  }, {});
}

export default {
  methods: {
    processError(error, { validator } = {}) {
      if (error.error.invalid_fields) {
        if (!validator) throw new Error("formValidator is not defined");
        const mergedErrors = errorParser(error.error.invalid_fields);
        validator(mergedErrors);
        return mergedErrors;
      }
      throw error;
    },
  },
};
