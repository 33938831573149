import ROUTE_NAME from "@/router/router.const";
import Login from "@/modules/auth/LoginPage.vue";
import AuthLayout from "@/layouts/AuthLayout";

export default {
  path: "/auth",
  component: AuthLayout,
  children: [
    {
      path: "",
      name: ROUTE_NAME.LOGIN,
      component: Login,
    },
    {
      path: "signup",
      name: ROUTE_NAME.REGISTRATION,
      component: () => import("@/modules/auth/RegistrationPage.vue"),
    },
    {
      path: "signup/check-email",
      name: ROUTE_NAME.REGISTRATION_SUCCESS,
      component: () => import("../RegistrationAfter.page.vue"),
    },
    {
      path: "email-confirmation/:uid/:token",
      name: ROUTE_NAME.REGISTRATION_AFTER,
      component: () => import("../RegistrationEmailConfirmation.page.vue"),
    },
    {
      path: "forgot-password",
      name: ROUTE_NAME.FORGOT_PASSWORD,
      component: () => import("../ForgotPassword.page.vue"),
    },
    {
      path: "forgot-password-after",
      name: ROUTE_NAME.FORGOT_PASSWORD_AFTER,
      component: () => import("../ForgotPasswordAfter.page.vue"),
    },
    {
      path: "reset-password/:uid/:token",
      name: ROUTE_NAME.RESET_PASSWORD,
      component: () => import("../CreateNewPassword.page.vue"),
    },
  ],
};
