import ROUTE_NAME from "@/router/router.const";
import BaseLayout from "@/layouts/Base.layout";

import settingsModule from "@/modules/settings/core/settings.router";
import orderOverview from "@/modules/order-overview/core/order-overview.router";

export default {
  path: "/dashboard/:layout",
  component: BaseLayout,
  children: [
    {
      path: "overview",
      name: ROUTE_NAME.OVERVIEW,
      component: () => import("@/modules/dashboard/DashboardOverView.page"),
    },
    {
      path: "projects",
      name: ROUTE_NAME.DASHOBARD_PROJECTS,
      component: () => import("@/modules/dashboard/DashboardProjects.page"),
    },
    {
      path: "projects/:order/approve",
      name: ROUTE_NAME.PROJECT_APPROVE,
      component: () => import("@/modules/feedback/FeedbackPage.vue"),
    },
    {
      path: "invoices",
      name: ROUTE_NAME.DASHOBARD_INVOICES,
      component: () => import("@/modules/dashboard/DashboardInvoices.page"),
    },
    {
      path: "subscription",
      name: ROUTE_NAME.DASHOBARD_SUBSCRIPTION,
      component: () => import("@/modules/dashboard/DashboardSubscription.page"),
    },
    {
      path: "subscription-processing",
      name: ROUTE_NAME.SUBSCRIPTION_PROCESS,
      component: () =>
        import(
          "@/modules/dashboard/additional-pages/DashboardSubcriptionProcess.page"
        ),
    },
    {
      path: "subscription-success",
      name: ROUTE_NAME.SUBSCRIPTION_SUCCESS,
      component: () =>
        import(
          "@/modules/dashboard/additional-pages/DashboardSuccessSubcription.page"
        ),
    },
    {
      path: "payment-handle",
      name: ROUTE_NAME.PAYMENT_HANDLE,
      component: () =>
        import(
          "@/modules/dashboard/additional-pages/DashboardHandlePayment.page"
        ),
    },
    {
      path: "subscription-invoice",
      name: ROUTE_NAME.SUBSCRIPTION_INVOICE,
      component: () =>
        import(
          "@/modules/dashboard/additional-pages/DashboardInvoiceSubcription.page"
        ),
    },
    {
      path: "notification",
      name: ROUTE_NAME.DASHOBARD_NOTIFICATION,
      component: () =>
        import("@/modules/dashboard/DashboardNotifications.page"),
    },
    {
      path: "admin-overview",
      name: ROUTE_NAME.ADMIN_OVERVIEW,
      component: () => import("@/modules/admin-panel/AdminOverview.page"),
    },
    {
      path: "admin-projects",
      name: ROUTE_NAME.ADMIN_PROJECTS,
      component: () => import("@/modules/admin-panel/AdminProjects.page"),
    },
    orderOverview,
    settingsModule,
    {
      path: "*",
      name: ROUTE_NAME.NOT_FOUND,
      component: () => import("@/modules/stuff/NotFound.page"),
    },
  ],
};
