<template>
  <div class="container inner-container">
    <div class="invite-page">
      <div v-if="validCode">
        <p class="invite-page__title">Please approve invitation</p>
        <div v-if="!hasCompany" class="invite-page__actions">
          <row justify="center">
            <cell>
              <app-button @click.native="decline" type="danger"
                >Decline invitation</app-button
              >
            </cell>
            <cell
              ><app-button @click.native="accept" type="success"
                >Accept invitation</app-button
              ></cell
            >
          </row>
        </div>
        <div v-else class="invite-page__actions">
          <p class="invite-page__subtitle">
            You have already part of the company "{{ comanyName }}"
          </p>
          <p class="invite-page__info">
            To be a part of the new company you need to leave the current
            company.
          </p>
          <row justify="center">
            <cell>
              <app-button @click.native="decline" type="danger"
                >Decline invitation</app-button
              >
            </cell>
            <cell
              ><app-button @click.native="leaveCompany" type="success"
                >Accept invitation</app-button
              ></cell
            >
          </row>
        </div>
      </div>
      <div v-else>
        <p class="invite-page__title">
          Sorry! Your code invitation is invalid!
        </p>
      </div>

      <app-divider class="divider_offset-vertical_lg"></app-divider>
      <app-button @click.native="goToOverview" type="primary"
        >Go Home</app-button
      >
    </div>
  </div>
</template>

<script>
import UserSvc from "@/api/services/user.service";
import initApi from "@/api/initApi";
import { transformInvalidFieldMessage } from "@/api/utils";

import { confirmModal } from "@/util/vue-utils";

export default {
  data() {
    return {
      validCode: false,
    };
  },
  computed: {
    codeInvitation() {
      return this.$route.params?.code ?? null;
    },
    hasCompany() {
      return this.$store.getters["user/userHasCompany"];
    },
    comanyName() {
      return this.$store.state.user.user.company?.name ?? "";
    },
  },
  mounted() {
    this.loadUser();
    this.checkInviteCode();
  },
  methods: {
    loadUser() {
      this.$store.dispatch("user/loadUser");
    },
    goToOverview() {
      this.$router.push(this.$router.push("/"));
    },
    async checkInviteCode() {
      try {
        const { is_active } = await new UserSvc(initApi).getInviteCode({
          code: this.codeInvitation,
        });
        if (is_active) this.validCode = true;
      } catch (error) {
        const errorField = error.error.invalid_fields?.code ?? "Unknown";
        this.$notify.error({
          message: errorField.join(""),
        });
      }
    },
    async accept() {
      if (!this.codeInvitation) return;
      try {
        await new UserSvc(initApi).userAcceptCompany({
          code: this.codeInvitation,
        });
        this.goToOverview();
      } catch (error) {
        const errorMsg = transformInvalidFieldMessage(error.error);
        throw new Error(errorMsg);
      }
    },
    async decline() {
      if (!this.codeInvitation) return;
      try {
        await new UserSvc(initApi).userDeclineCompany({
          code: this.codeInvitation,
        });
        this.goToOverview();
      } catch (error) {
        const errorMsg = transformInvalidFieldMessage(error.error);
        throw new Error(errorMsg);
      }
    },
    async leaveCompany() {
      try {
        await confirmModal(this, {
          message: `Leave the company ${this.comanyName}?`,
        });
        await new UserSvc(initApi).leaveCompany();
        this.loadUser();
        this.accept();
      } catch (e) {
        throw new Error("Canceled");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  text-align: center;
}
.invite-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  &__ {
    &title {
      color: $black;
      font-weight: $weight-bold;
      font-size: $size-3xl;
      font-family: "Sora";
    }
    &subtitle {
      color: $black;
      font-weight: $weight-bold;
      font-size: $size-xl;
      font-family: "Sora";
    }
    &info {
      margin-top: 20px;
      margin-bottom: 18px;
      padding: 0 15%;
      color: $color-icon-gray;
      text-align: center;
      font-size: $size-sm;
    }
    &actions {
      margin: 20px 0;
    }
  }
}

</style>
