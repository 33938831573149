import AuthAdmin from "@/layouts/AuthAdmin.layout";
import LoginAdmin from "@/modules/auth-admin/LoginAdmin.page";
import ROUTE_NAME from "@/router/router.const";
export default {
  path: "/admin",
  component: AuthAdmin,
  children: [
    {
      path: "",
      name: ROUTE_NAME.ADMIN_LOGIN,
      component: LoginAdmin,
    },
  ],
};
