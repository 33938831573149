<template>
  <div class="order-download">
    <a
      v-if="enableArchive"
      class="button el-button el-button--default el-button--medium button"
      :href="order.link_to_archive"
      target="_blank"
      download
    >
      <div class="button__row">
        <span><DownloadIcon /></span>
        <span class="button__text button__text_offset_left"
          >Download Files .zip</span
        >
      </div>
    </a>
    <p v-else>Sorry! Your archive isn't ready!</p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      order: (state) => state.orderOverview.order,
    }),
    enableArchive() {
      return !!this.order.link_to_archive;
    },
  },
};
</script>
