<template>
  <div class="auth-wrapper auth-wrapper_variant_admin">
    <div class="container-fluid">
      <div class="auth-layout">
        <header class="auth-header">
          <div class="auth-header__block">
            <div class="navigation-link">
              <div class="navigation-link__arrow">
                <ArrowLeftIcon />
              </div>
              <router-link
                :to="{ name: $options.routeLink.LOGIN }"
                class="navigation-link__text"
                >Back to Sign In</router-link
              >
            </div>
          </div>
          <div class="auth-header__logo">
            <app-logo />
            <div class="auth-header__sub-title">
              <base-text variant="hamburger" size="s"
                >Model Project Management</base-text
              >
            </div>
          </div>
          <div class="auth-header__block"></div>
        </header>
        <main class="auth-layout__content container">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import ROUTE_NAME from "@/router/router.const";
export default {
  routeLink: ROUTE_NAME,
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  background-color: $background;
  &_variant_admin {
    background-color: $admin-layout-bg;
  }
}

.auth-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    section {
      padding-top: 40px;
    }
  }
  &__admin-nav {
    padding-top: $space-2xl;
    text-align: right;
  }
}

.auth-header {
  display: flex;
  align-items: center;
  margin-top: 28px;
  width: 100%;
  &__logo {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__logo-col {
    display: inline-flex;
    align-items: flex-end;
  }
  &__block {
    width: 400px;
    &:first-child {
      padding-left: 44px;
      .vue-portal-target {
        text-align: left;
      }
    }
    &:last-child {
      padding-right: 44px;
      .vue-portal-target {
        text-align: right;
      }
    }
  }
  &__sub-title {
    margin-top: $space-2md;
  }
}
</style>
