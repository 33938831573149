import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Filters from "@/filters";
import heroIcons from "@/components/ui/heroicons-inject";
import defineGlobalElements from "./util/defineGlobalElements";
import defineGlobalComponents from "./util/defineGlobalComponents";
import "@/util/svg-icons";
import "./assets/scss/app.scss";
import defineValidation from "@/util/defineValiadation";
import PortalVue from "portal-vue";
import { ObserveVisibility } from "vue-observe-visibility";
import "./plugins/element.js";
import Dayjs from "dayjs";

defineValidation(Vue);

Vue.use(PortalVue);

Vue.use(defineGlobalElements);
Vue.use(Filters);
Vue.directive("observe-visibility", ObserveVisibility);

Vue.config.productionTip = false;
Vue.prototype.$dayjs = Dayjs;

heroIcons(Vue);
defineGlobalComponents(Vue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
