<script>
export default {
  props: {
    smSpacing: Boolean,
    xsSpacing: Boolean,
    largeSpacing: Boolean,
    header: Boolean,
  },
  functional: true,
  render(h, context) {
    let classes = [
      "card-content",
      context.data.staticClass,
      context.data.class,
    ];
    if (context.props.smSpacing) classes.push("card-content_sm");
    if (context.props.xsSpacing) classes.push("card-content_xs");
    if (context.props.largeSpacing) classes.push("card-content_large");
    if (context.props.header) classes.push("card-content_header");
    return h("div", { class: classes }, context.children);
  },
};
</script>

<style lang="scss">
.card-content {
  padding: 24px;
  color: $card-text;
  &.card-content_large {
    padding: 26px;
    @include media(">=desktop") {
      padding: 40px;
    }
  }
  &.card-content_sm {
    padding: 20px;
  }
  &.card-content_xs {
    padding: 16px;
  }
  &.card-content_header {
    padding-bottom: 14px;
  }
}
</style>
