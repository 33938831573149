<template>
  <div>
    <div class="feedback-footer">
      <row justify="between" align="center">
        <cell>
          <row>
            <cell :key="item.id" v-for="(item, idx) in feedbackRounds">
              <app-button
                :disabled="
                  feedbackRounds.length - 1 > idx || nextRoundCount < 0
                "
                disabledGrayVariant
                notClickable
                type="primary"
                size="small"
              >
                {{
                  item.number === 0
                    ? "Initial Round"
                    : `Feedback Round ${item.number}`
                }}
                {{ feedbackRounds.length - 1 > idx ? "submitted" : "" }}
              </app-button>
            </cell>
            <cell v-if="nextRoundCount > 0">
              <app-button
                :key="i"
                v-for="i in nextRoundCount"
                notActive
                notClickable
                type="primary"
                size="small"
              >
                Feedback Round {{ activeRound.number + i }}
              </app-button>
            </cell>
          </row>
        </cell>
        <cell v-if="enableFinish">
          <app-button
            :notClickable="!enabableRoundFlow"
            :notActive="!enabableRoundFlow"
            @click.native="$emit('finish:round', activeRound)"
            type="primary"
            :loading="loading"
            >Complete feedback round</app-button
          >
        </cell>
      </row>
    </div>
  </div>
</template>

<script>
import { FEEDBACK_ROUND_STATUS, PROJECT_STATUS } from "@/util/const.js";

export default {
  props: {
    feedbackRounds: {
      type: Array,
      required: true,
    },
    maxRounds: {
      type: Number,
      required: true,
    },
    activeRound: {
      type: Object,
      required: true,
    },
    orderStatus: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },
  computed: {
    nextRoundCount() {
      return this.maxRounds - this.feedbackRounds.length;
    },
    enabableRoundFlow() {
      return (
        this.activeRound.results_count === this.activeRound.needed_results_count
      );
    },
    enableFinish() {
      return (
        this.activeRound.recent_status === FEEDBACK_ROUND_STATUS.FINISHED &&
        this.orderStatus === PROJECT_STATUS.IS_SUBMITTED.value
      );
    },
  },
  methods: {
    isFinishedRound(itemRound) {
      return itemRound.recent_status === FEEDBACK_ROUND_STATUS.FINISHED;
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-footer {
  padding: $space-lg;
  background-color: $white;
  box-shadow: $card-shadow;
}
</style>
