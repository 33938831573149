<template>
  <base-text
    class="help-link"
    appearance="medium"
    variant="text-dark-color"
    size="s"
    tag="p"
  >
    <slot name="text"></slot>
    <base-text tag="span">
      <contacts-widget>
        <template v-slot:activator="{ open }">
          <a href="#" @click.prevent="open">
            <slot name="link-text">get help</slot></a
          ></template
        ></contacts-widget
      >
    </base-text>
  </base-text>
</template>

<script>
export default {
  components: {
    contactsWidget: () => import("@/components/widgets/ContactsWidget.vue"),
  },
};
</script>

<style lang="scss" scoped>
.help-link {
  display: inline-flex;
  gap: 4px;
}
</style>
