import Vue from "vue";
import Vuex from "vuex";

import user from "./user.store";
import garment from "@/modules/order/core/garment.store";
import order from "@/modules/order/core/order.store";
import look from "@/modules/order/core/look.store";
import cms from "./cms.store";
import orderOverview from "@/modules/order-overview/core/order-overview.store.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    garment,
    order,
    look,
    cms,
    orderOverview,
  },
});
