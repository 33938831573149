import ModelSvc from "@/api/services/appearance.service";
import OrderSvc from "@/modules/order/core/order.service";
import initApi from "@/api/initApi";

export default {
  namespaced: true,

  state: () => ({
    order: {
      id: -1,
    },
    modelsCount: {
      models: 0,
      poses: 0,
    },
  }),

  mutations: {
    update(state, order) {
      state.order = order;
    },

    updateOrder(state, payload) {
      state.order = payload;
    },
    SET_MODELS_COUNT(state, payload) {
      state.modelsCount = payload;
    },
  },

  actions: {
    async loadOrder({ commit }, { api, id }) {
      const response = await new OrderSvc(api).order(id);
      commit("updateOrder", response);
    },

    async fetchModelsCount({ commit }) {
      const response = await new ModelSvc(initApi).getModelsCount();
      commit("SET_MODELS_COUNT", response);
    },
  },
};
