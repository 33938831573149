import { render, staticRenderFns } from "./UiLightBox.vue?vue&type=template&id=24d56d1c&"
import script from "./UiLightBox.vue?vue&type=script&lang=js&"
export * from "./UiLightBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports