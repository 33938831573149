<template>
  <header class="order-header">
    <div class="order-header__wrapper">
      <app-logo betaVariant />
      <span><portal-target name="order-header-title"></portal-target></span>
      <portal-target name="order-header-finish">
        <div class="header-row">
          <AvailibleLooksWidget />
          <div class="border-item"></div>
          <div class="header-row__col"><NotificateWrapper /></div>
          <div>
            <UserDropDownMenu />
          </div>
        </div>
      </portal-target>
    </div>
  </header>
</template>

<script>
export default {
  components: {
    AvailibleLooksWidget: () =>
      import("@/components/widgets/AvailibleLooksWidget.vue"),
    NotificateWrapper: () =>
      import("@/components/widgets/notificate/NotificateWrapper"),
    UserDropDownMenu: () =>
      import("@/components/widgets/notificate/UserDropDownMenu.vue"),
  },
};
</script>

<style lang="scss">
$order-header: $gray500;
.order-header {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px $container-inner;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
    color: $order-header;
    font-weight: 500;
  }
  &__close {
    margin-left: 20px;
    line-height: 1;
    cursor: pointer;
    &:hover {
      color: $danger;
      & svg {
        color: $danger;
      }
    }
    svg {
      display: inline-block;
      margin-left: 13px;
      width: 20px;
      height: 20px;
      color: $icon-color;
      vertical-align: middle;
    }
  }
}

.header-row {
  @extend %d-flex;
  @extend %d-flex_justify_end;
  @extend %d-flex_align_center;
  &__col {
    margin: 0 20px 0 0;
  }
}
.border-item {
  height: 24px;
  margin: 0 18px;
  border-left: 1px solid $text-dark-color;
}
</style>
