const ROUTE_NAME = {
  CLIENT_PANEL: "client",
  ADMIN_PANEL: "admin-panel",
  LOGIN: "login",
  REGISTRATION: "registration",
  REGISTRATION_SUCCESS: "registration_success",
  REGISTRATION_AFTER: "registration_after",
  EMAIL_CONFIRMATION: "email_confirmation",
  BASE: "base",
  FORGOT_PASSWORD: "forgot-password",
  FORGOT_PASSWORD_AFTER: "forgot-password-after",
  RESET_PASSWORD: "reset-password",
  DASHBOARD: "dashboard",
  OVERVIEW: "overview",
  DASHOBARD_PROJECTS: "projects",
  DASHOBARD_INVOICES: "invoices",
  DASHOBARD_SUBSCRIPTION: "subscription",
  SUBSCRIPTION_PROCESS: "subscription-processing",
  SUBSCRIPTION_SUCCESS: "subscription-success",
  SUBSCRIPTION_INVOICE: "subscription-invoice",
  PAYMENT_HANDLE: "payment-handle",
  DASHOBARD_NOTIFICATION: "notification-page",
  DASHOBARD_BILLING_SETTINGS: "billing-settings",
  DASHOBARD_BILLING_SETTINGS_PAYMENT_HANDLE: "billing-settings-payment-handle",
  PROJECT_APPROVE: "project-approve",
  SETTINGS: "settings",
  PROFILE: "profile",
  ORGANISATION: "organisation",
  TEAM_MEMBERS: "team-members",
  ORDER_START: "order-start",
  ORDER_MODLES: "order-models",
  ORDER_CONFIGURE: "order-configuration",
  ORDER_UPLOAD_FILES: "order-upload-files",
  ORDER_CREATE_LOOK: "order-create-look",
  ORDER_CHECKOUT: "order-checkout",
  ORDER_INVOICE: "order-invoice",
  ORDER_THANK: "order-thank",
  ORDER_FINISH: "order-finish",
  CHANGE: "change",
  ADMIN_LOGIN: "admin-login",
  ADMIN_OVERVIEW: "admin-overview",
  ADMIN_PROJECTS: "admin-projects",
  NOT_FOUND: "not-found",
  NOT_FOUND_GENERAL: "not-found-page",
  ORDER_OVERVIEW: "order-overview",
  ORDER_SINGLE_LOOK: "order-single-look",
  ORDER_RESULTS: "order-results",
  MOBILE_LAYOUT: "mobile-layout",
  INVITE_PAGE: "invite",
  LOGIN_BY_TOKEN: "login-by-token",
};

export default ROUTE_NAME;
