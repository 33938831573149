<template>
  <el-table v-bind="{ ...$props, ...$attrs }" stripe style="width: 100%">
    <el-table-column
      v-for="{ key, label, prop } in columnGetter"
      :key="key"
      :prop="prop"
      :label="label"
    >
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    data: Array,
    tableLabels: Array,
  },
  computed: {
    columnGetter() {
      if (this.data.length > 1) {
        return Object.keys(this.data[0]).map((key, idx) => ({
          key,
          prop: key,
          label: this.tableLabels[idx],
        }));
      }
      return null;
    },
  },
};
</script>
