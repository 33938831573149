<template>
  <div class="order-overview">
    <div class="order-overview__header">
      <div class="order-overview__link">
        <row align="center" justify="between">
          <cell>
            <div class="navigation-link">
              <div class="navigation-link__arrow">
                <ArrowLeftIcon />
              </div>
              <a
                href="#"
                @click.prevent="goProjects"
                class="navigation-link__text"
                >Back to Project Overview</a
              >
            </div>
          </cell>
          <cell v-if="order">
            <row>
              <cell>
                <base-text size="xl" variant="title-color">{{
                  order.id
                }}</base-text>
              </cell>
              <cell v-if="order.status">
                <app-label :type="statusType(order.status).type">{{
                  statusType(order.status).label
                }}</app-label>
              </cell>
            </row>
          </cell>
          <cell>
            <div
              v-if="activeRound && activeRound.result_feedbacks.length"
              class="view-controller"
            >
              <div
                :key="item.id"
                v-for="item in navigateRoutes"
                class="view-controller__item"
                :class="{
                  active: $route.name === item.name,
                  vertical: item.id === 1,
                }"
                @click="navigateRoute(item)"
              >
                <ViewListIcon />
              </div>
            </div>
          </cell>
        </row>
      </div>
      <row v-if="order" align="center" justify="between">
        <cell>
          <row>
            <cell
              ><base-text appearance="medium" size="s" variant="text-icon-color"
                >Order #{{ order.id }}</base-text
              ></cell
            >
            <cell
              ><base-text
                appearance="medium"
                size="s"
                variant="text-icon-color"
                >{{ dateFormatter(order.created) }}</base-text
              ></cell
            >
            <cell
              ><base-text
                v-if="order && order.feedback_rounds.length"
                appearance="medium"
                size="s"
                variant="text-icon-color"
                >{{ activeRound.looks.length }} Look{{
                  activeRound.looks.length | wordEnd
                }}</base-text
              ></cell
            >
          </row>
        </cell>
        <cell>
          <OrderDownloads />
        </cell>
      </row>
    </div>
    <div class="order-overview__body">
      <app-loader v-if="isLoading" />
      <transition name="fade" mode="out-in">
        <router-view v-if="order && order.feedback_rounds.length"></router-view>
      </transition>
    </div>
    <div class="footer-container">
      <OrderFooterControl
        v-if="order && order.feedback_rounds.length"
        :maxRounds="order.max_feedback_rounds"
        :feedbackRounds="order.feedback_rounds"
        :activeRound="activeRound"
        :loading="loading"
        :orderStatus="order.status"
        @finish:round="finishRound"
      />
    </div>
  </div>
</template>

<script>
import { formattedDateWithTime } from "@/api/utils";

import { PROJECT_STATUS, ADMIN_PROJECT_STATUS_LABEL } from "@/util/const.js";
import AdminSvc from "@/modules/admin-panel/core/admin.service";
import initApi from "@/api/initApi";

import { mapActions, mapState } from "vuex";

import ROUTE_NAME from "@/router/router.const";
import OrderDownloads from "@/modules/order-overview/components/OrderDownloads";
import OrderFooterControl from "@/modules/order-overview/components/OrderFooterControl";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      order: (state) => state.orderOverview.order,
      isLoading: (state) => state.orderOverview.isLoading,
    }),
    activeRound() {
      return this.$store.getters["orderOverview/activeRound"];
    },
    navigateRoutes() {
      return [
        { id: 0, name: ROUTE_NAME.ORDER_RESULTS },
        { id: 1, name: ROUTE_NAME.ORDER_SINGLE_LOOK },
      ];
    },
    paidOrderStatus() {
      return this.order.status === PROJECT_STATUS["IS_SUBMITTED"].value;
    },
  },
  methods: {
    ...mapActions({
      loadOrder: "orderOverview/order",
      getLooks: "orderOverview/setLooks",
      setLoading: "orderOverview/setLoader",
    }),
    async loadData() {
      this.setLoading(true);
      try {
        await this.loadOrder({
          api: initApi,
          id: this.$route.params.order,
        });
      } finally {
        this.setLoading(false);
      }
    },
    async finishRound(round) {
      try {
        this.loading = true;
        await new AdminSvc(initApi).finshRoundAdmin(round.id);
        await this.loadData();
        this.$notify({
          message: "Round was finished!",
          type: "success",
        });
      } catch (error) {
        throw new Error(error.error.invalid_fields[0]);
      } finally {
        this.loading = false;
      }
    },
    statusType(status) {
      return ADMIN_PROJECT_STATUS_LABEL[status];
    },
    dateFormatter(date) {
      return formattedDateWithTime(this.$dayjs, date);
    },
    goProjects() {
      this.$router.push({ name: ROUTE_NAME.ADMIN_PROJECTS });
    },
    navigateRoute(item) {
      if (ROUTE_NAME.ORDER_RESULTS === item.name) {
        this.$router.push({ name: ROUTE_NAME.ORDER_RESULTS });
      } else {
        this.$router.push({
          name: ROUTE_NAME.ORDER_SINGLE_LOOK,
          params: { look: this.activeRound.result_feedbacks[0].id },
        });
      }
    },
  },
  async created() {
    await this.loadData();
  },
  components: {
    OrderDownloads,
    OrderFooterControl,
  },
};
</script>

<style lang="scss">
.order-overview {
  display: flex;
  flex: 1;
  flex-direction: column;
  &__ {
    & {
      &header {
        padding: $space-2xl;
      }
    }
    &body {
      flex: 1;
      background-color: $background;
      padding: $space-2xl;
      padding-bottom: 104px;
      position: relative;
    }
    &link {
      margin-bottom: 16px;
    }
    &title {
      margin-bottom: 8px;
    }
  }
}

.footer-container {
  position: fixed;
  bottom: 0;
  left: calc(#{$sidebar-width} + #{$container-inner});
  z-index: $z-index-1;
  width: calc(100% - (#{$sidebar-width} + #{$container-inner}));
}
</style>
