<template>
  <div class="preivew-avatar-wrapper">
    <div class="preivew-avatar-wrapper__image">
      <div class="img-preview" v-show="file">
        <img class="img-preview__result" :src="file" alt="" />
      </div>
    </div>
    <div class="preivew-avatar-wrapper__loader">
      <row justify="center">
        <cell>
          <div class="file-loader">
            <input
              class="file-loader__input"
              type="file"
              accept="image/*"
              name="choose-file"
              @change="uploadImage"
              id="file-input"
              ref="file-input"
            />
            <label calss="file-loader__label" for="file-input">
              <div class="action-file-button">
                <div class="action-file-button__logo">
                  <PencilAltIcon />
                </div>
                <div class="action-file-button__text">
                  {{ hasFile ? "Edit Image" : "Upload File" }}
                </div>
              </div>
            </label>
          </div>
        </cell>
        <cell>
          <div v-if="hasFile" @click="deleteFile" class="action-file-button">
            <div class="action-file-button__logo">
              <TrashIconOutline />
            </div>
          </div>
        </cell>
      </row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: String,
      default: "",
    },
    hasFile: Boolean,
  },
  data() {
    return {
      imageResult: this.file,
    };
  },
  methods: {
    async uploadImage(event) {
      let data = new FormData();
      data.append("avatar", event.target.files[0]);
      this.$emit("upload", data);
    },
    deleteFile() {
      this.$refs["file-input"].value = "";
      this.$emit("delete-file");
    },
  },
};
</script>

<style lang="scss">
.preivew-avatar-wrapper {
  &__image {
    display: flex;
    justify-content: center;

    @extend %m_bottom_2lg;
  }
}
.img-preview {
  overflow: hidden;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  text-align: center;

  &__ {
    &result {
      max-width: 100%;
      max-height: 100%;

      @extend %full-image;
    }
  }
}

.file-loader {
  &__ {
    &input {
      @extend .visually-hidden;
    }
  }
}

.action-file-button {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border: 1px solid $border;
  border-radius: $--border-radius-base;
  box-shadow: $card-shadow;
  cursor: pointer;
  &__ {
    &logo {
      color: $icon-color-gray;
    }
    &text {
      margin-left: 10px;
      color: $text;
      font-size: $size-s;
    }
  }
}
</style>
