export default class ErrorHandler extends Error {
  constructor(error) {
    super();
    this._error = error;
    this.message = "";
  }

  error() {
    let res = new BaseError().error(0);
    if (!this._error) return res;
    let errorMsg = this._error.detail;
    if (errorMsg) {
      this.message = res.error.message;
      return { ...res, message: this.message };
    }
    res = this.getResponseError();
    return {
      ...res,
      message: this.message,
    };
  }

  getBaseErr(code = -1, message = "unknown error") {
    return {
      status: "ERR",
      error: {
        code: code,
        message: message,
      },
    };
  }

  translateErrorText(text) {
    if (typeof text !== "string") {
      return text;
    }
    if (text.includes("502 Bad Gateway")) {
      return "failed to connect to server, try again later";
    }

    if (text.includes("etwork") && text.includes("rror")) {
      return "failed to connect to server, try again later";
    }

    if (text.includes("imeout") && text.includes("xceed")) {
      return "failed to connect to server, try again later";
    }

    if (text.includes("valid") && text.includes("nection")) {
      return "failed to connect to server, try again later";
    }

    return text;
  }

  getResponseError() {
    let res = this.getBaseErr();
    let message = this._error.response.data;
    if (!message)
      message = this.translateErrorText(this._error.response.statusText);
    if (typeof message !== "string") {
      if ("detail" in message) {
        this.message = message.detail;
        return {
          ...res,
          message: message.detail,
        };
      }
      res.error.invalid_fields = {};
      for (let field in message) {
        res.error.invalid_fields[field] = message[field];
      }
      res.error.code = this._error.response.status;
      this.message = res.error.message;
    }
    // overwrite the error code if authentication failed or access is denied
    if ([401, 403, 404].includes(this._error.response.status)) {
      res.error.code = this._error.response.status;
    }
    return res;
  }
}

export class ErrorFields {
  constructor(invalidFields) {
    this.invalidFields = invalidFields;
  }

  error() {
    return {
      response: {
        data: {
          invalid_fields: this.invalidFields,
          message: "Invalid fields",
        },
        status: -1,
        statusText: "Invalid fields",
      },
    };
  }
}

export class BaseError extends Error {
  constructor(code = -1, message = "unknown error", args) {
    super();
    this.code = code;
    this.message = message;
    this.args = args;
  }

  error() {
    return {
      status: "ERR",
      error: {
        code: this.code,
        message: this.message,
      },
    };
  }
}
