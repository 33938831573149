<template>
  <section class="auth-login">
    <div class="content-wrapper">
      <app-card>
        <app-card-content large-spacing class="auth-login__fields">
          <app-title without-spacing tag="h1" class="auth-login__title"
            >Sign in
          </app-title>
          <ValidationObserver v-slot="{ invalid }" ref="formValidator">
            <form
              class="auth-form auth-login__form"
              @submit.prevent="login(invalid)"
            >
              <base-field-v
                name="email"
                rules="required|email"
                :debounce="500"
                v-model="formData.email"
                label="Email Address"
                placeholder="You@email.com"
                type="email"
                message="This email is invalid"
              />
              <base-field-v
                name="password"
                rules="required|min:8"
                :debounce="500"
                v-model="formData.password"
                placeholder="•••••••••••••••••"
                native-type="password"
                label="Password"
                tip="Use at least 8 characters"
                show-password
              >
              </base-field-v>

              <app-button
                expanded
                :disabled="invalid"
                native-type="submit"
                variantUppcase
                class="auth-login__submit"
                >Sign in</app-button
              >
            </form>
          </ValidationObserver>
          <app-divider class="auth-login__divider" />
          <app-button
            @click.native="navigateSignUp"
            expanded
            type="primary"
            native-type="button"
            variantUppcase
            >Start Free Trial</app-button
          >
        </app-card-content>
      </app-card>
      <div class="auth-tip">
        Forgot your password?
        <router-link :to="{ name: ROUTE_NAME.FORGOT_PASSWORD }"
          >Get a new password
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import formErrorsMixin from "@/mixins/formErrors.mixin";

export default {
  mixins: [formErrorsMixin],
  inject: ["api", "ROUTE_NAME"],
  data: () => ({
    formData: {
      email: "",
      password: "",
    },
    chatScriptIsLoaded: false,
  }),

  async mounted() {
    await this.checkLoginedUser();
    this.hideRecaptchaBadge();
  },

  methods: {
    async login(invalid) {
      try {
        if (invalid) "Please fill email and password";
        await this.api.updateTokensByCredentials(this.formData);
        this.$router.push({
          name: this.ROUTE_NAME.OVERVIEW,
          params: { layout: this.ROUTE_NAME.CLIENT_PANEL },
        });
      } catch (error) {
        this.processError(error, {
          validator: this.$refs.formValidator.setErrors,
        });
      }
    },
    navigateSignUp() {
      this.$router.push({ name: this.ROUTE_NAME.REGISTRATION });
    },
    hideRecaptchaBadge() {
      const target = document.getElementsByClassName("grecaptcha-badge")[0];
      if (!target) return;
      target.style.visibility = "hidden";
    },
    async checkLoginedUser() {
      try {
        let tokens = await this.api.getTokensFromRepoOrRefresh();
        if (tokens) {
          this.$router.push({
            name: this.ROUTE_NAME.OVERVIEW,
            params: { layout: this.ROUTE_NAME.CLIENT_PANEL },
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-login {
  margin: 0 auto;
  flex-grow: 1;
  & &__title.title {
    margin-bottom: 40px;
  }
  &__divider {
    margin-top: 18px;
    margin-bottom: 18px;
    @include media(">=desktop") {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
  &__submit {
    margin-top: 0 !important;
  }
}

.content-wrapper {
  width: min(450px, 100%);
  margin: 0 auto;
}

.auth-tip {
  color: $field-tip;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin-top: 40px;
}
</style>
