<template>
  <div>
    <el-checkbox v-bind="$props" @change="$emit('input', $event)">
      <slot name="label"></slot>
    </el-checkbox>
    <slot name="info"></slot>
    <slot name="custom-error" v-if="error"></slot>
    <p v-if="!showError" class="text-danger">{{ error }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: String,
    error: String,
    showError: Boolean,
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-checkbox {
    &__label {
      white-space: initial;
    }
  }
}
</style>
