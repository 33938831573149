export const STATUS = {
  INIT: "INIT",
  LOADING: "LOADING",
  FINISH: "FINISH",
  ERROR: "ERROR",
  LOADED: "LOADED",
};

export const CMS_PAGES = {
  ORDER_START: "PAGE_START_NEW_PROJECT",
  ORDER_FINISH: "PAGE_THANKS_FOR_ORDER",
  OVERVIEW_INFO: "OVERVIEW_INFO",
};

export const MAX_FILE_SIZE = 50;
export const MIN_FILE_RESOLUTION = 1500;

export const DESKTOP_WIDTH = 1200;
export const LAPTOP_WIDTH = 768;

export const PROJECT_STATUS = {
  IS_INITIAL: {
    type: "pending",
    label: "Initiated",
    value: "IS_INITIAL",
  },
  IS_COMPLETED: {
    type: "success",
    label: "Completed",
    value: "IS_COMPLETED",
  },
  STATUS_NEED_FEEDBACK: {
    type: "warning",
    label: "Needs feedback",
    value: "STATUS_NEED_FEEDBACK",
  },
  IS_SUBMITTED: {
    type: "info",
    label: "Submitted",
    value: "IS_SUBMITTED",
  },
  PAYMENT_IS_WAITING: {
    type: "success",
    label: "Payment is waiting",
    value: "PAYMENT_IS_WAITING",
  },
};

export const ADMIN_PROJECT_STATUS_LABEL = {
  IS_INITIAL: {
    type: "pending",
    label: "Ongoing project",
    value: "IS_INITIAL",
  },
  IS_COMPLETED: {
    type: "success",
    label: "Completed",
    value: "IS_COMPLETED",
  },
  STATUS_NEED_FEEDBACK: {
    type: "pending",
    label: "Result submitted",
    value: "STATUS_NEED_FEEDBACK",
  },
  IS_SUBMITTED: {
    type: "warning",
    label: "Results required",
    value: "IS_SUBMITTED",
  },
  PAYMENT_IS_WAITING: {
    type: "success",
    label: "Payment is waiting",
    value: "PAYMENT_IS_WAITING",
  },
};

export const LOOK_STATUS = {
  APPROVED_STATUS: "APPROVED",
  DECLINED_STATUS: "DECLINED",
};

export const FEEDBACK_ROUND_STATUS = {
  INITIAL: "INITIAL",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
};

export const FEEDBACK_STATUS = {
  ALL: {
    label: "All images",
    value: "ALL",
  },
  NEED_FEEDBACK: {
    label: "Need feedback",
    value: null,
    type: "pending",
  },
  APPROVED: {
    label: "Approved",
    value: "APPROVED",
    type: "success",
  },
  DECLINED: {
    label: "Declined",
    value: "DECLINED",
    type: "danger",
  },
};

export const INVOICE_STATUS = {
  INITIAL: "ORDER_TYPE", // deprecated
  SUBSCRIPTION_PURCHASE: "SUBSCRIPTION_PURCHASE",
  SUBSCRIPTION_RENEWAL: "SUBSCRIPTION_RENEWAL",
};

export const PAYMENT_TYPES_ENUM = {
  ADYEN: "ADYEN",
  INVOICE: "INVOICE",
  MOLLIE: "MOLLIE",
};

export const ADYEN_PAYMENT_STATUS = {
  AUTHORISED: "Authorised",
};

export const INFLOW_ORGANISATION = {
  SETTINGS: "user settings",
  SUBSCRIPTION: "create subscription",
};

export const SUBSCRIPTIONS_PLAN_NAME = {
  STARTER: "Starter",
};

export const PAYMENT_STATUS = {
  PAYMENT_IS_WAITING: "PAYMENT_IS_WAITING",
  PAYMENT_IS_SUCCESS: "PAYMENT_IS_SUCCESS",
  PAYMENT_IS_DECLINED: "PAYMENT_IS_DECLINED",
};

export const SUBSCRIPTION_PAYMENT_STATUS = {
  NEW: "NEW",
  FAILED: "FAILED",
  SUCCEED: "SUCCEED",
  WAITS_FOR_CONFIRMATION: "WAITS_FOR_CONFIRMATION",
};
