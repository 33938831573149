<template>
  <div class="app-field" :class="className">
    <div v-if="label" class="app-field__label">
      <slot name="label">
        {{ label }}
        <span class="app-field__tip">{{ tip }} </span>
      </slot>
    </div>
    <el-input
      v-bind="$props"
      :type="nativeType"
      :rows="rows"
      :disabled="isDisabled"
      size="medium"
      class="app-input"
      @input="$emit('input', $event)"
      v-on="$listeners"
      :class="{
        'app-input_error': error && error.length,
        'app-input_transparent': transparent,
      }"
    />
    <div class="app-field__error" v-if="!hideError">
      <div class="control-hint" v-show="error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "baseField",
  inheritAttrs: false,
  props: {
    value: {
      required: true,
    },
    label: String,
    placeholder: String,
    tip: String,
    showPassword: Boolean,
    iconPack: String,
    nativeType: String,
    rows: Number,
    name: String,
    error: String,
    className: String,
    hideError: Boolean,
    isDisabled: Boolean,
    type: String,
    transparent: Boolean,
  },
};
</script>

<style lang="scss">
.app-field {
  margin-bottom: 24px;
  font-weight: 500;
  input {
    box-shadow: $input-shadow;
    font-weight: 500;
    &::placeholder {
      color: #a1a1aa;
      font-weight: 500;
    }
  }
  &_ {
    &space_none {
      margin: 0;
    }
  }
  &__label-row {
    display: flex;
    justify-content: space-between;
  }
  &__prepend {
    flex-shrink: 0;
  }
  &__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    width: 100%;
    color: $label-color;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
  }
  &__tip {
    color: $field-tip;
    font-weight: 500;
  }
  &__error {
    position: relative;
  }
}

.app-input {
  input {
    box-shadow: unset;
    transition: box-shadow 0.2s ease-in;
    &:focus {
      box-shadow: 0 0 0 2px #00c2d7, $input-shadow;
    }
  }
  textarea {
    padding: $space-2md;
    border-color: transparent;
    background: $background;
    box-shadow: $input-shadow;
    &::placeholder {
      color: $text-light-info;
    }
  }

  &_error {
    input {
      border-color: $danger;
    }
  }
}
</style>
