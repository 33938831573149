import UserSvc from "@/api/services/user.service";
import NotifySvc from "@/api/services/notification.service";

import initApi from "@/api/initApi";
import SubcrptnSvc from "@/api/services/subscription.service";
export default {
  namespaced: true,

  state: () => ({
    user: {
      active_subscription: {},
      available_trial_images: 0,
      email: "",
      name: "",
      avatar: "",
      country: "",
      phone_number: "",
    },
    notifications: null,
    availibleLooks: 0,
  }),

  getters: {
    userHasCompany(state) {
      return !!state.user.company;
    },
    isTrialPlan(state) {
      return state.user.active_subscription?.plan_info?.is_start ?? true;
    },
    userActiveSubscription(state) {
      return state.user.active_subscription;
    },
    userSubscriptionTeamSize(state) {
      return state.user.active_subscription?.plan_info?.team_size ?? 0;
    },
    userIsGranted(state) {
      return state.user?.access_granted ?? false;
    },
    userPaymentFailed(state) {
      return state.user?.payment_in_retrying_state ?? false;
    },
    userOrganizationOwner(state) {
      return state.user?.is_company_owner ?? false;
    },
    hadPaidSubscription(state) {
      return state.user?.had_paid_subscription ?? false;
    },
  },

  mutations: {
    updateUser(state, payload) {
      state.user = payload;
    },
    updateUserAvatar(state, payload) {
      state.user.avatar = payload;
    },
    SET_NOTIFICATION(state, payload) {
      state.notifications = payload;
    },
    changeStateNotify(state, { id, was_read }) {
      const notifyItemIdx = state.notifications.results.findIndex(
        (item) => item.id === id
      );
      if (state.notifications.results[notifyItemIdx])
        state.notifications.results[notifyItemIdx].was_read = was_read;
    },
    removeNotify(state, { id }) {
      const notifyItemIdx = state.notifications.results.findIndex(
        (item) => item.id === id
      );
      const size = 1;
      if (state.notifications.results[notifyItemIdx]) {
        state.notifications.results.splice(notifyItemIdx, size);
      }
    },
    updateUserByFieldName(state, { payload, name }) {
      state.user[name] = payload;
    },
  },
  actions: {
    async loadUser({ commit }) {
      const userResponse = await new UserSvc(initApi).getUserData();
      commit("updateUser", userResponse);
    },
    async updateNotifications({ commit }) {
      const response = await new NotifySvc(initApi).notifyList();
      commit("SET_NOTIFICATION", response);
    },

    async loadAvailibleLooks({ state }) {
      const response = await new SubcrptnSvc(initApi).available();
      state.availibleLooks = response.available_looks;
    },
  },
};
