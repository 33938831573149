import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  min,
  max,
  numeric,
} from "vee-validate/dist/rules.umd.js";
import enLocale from "vee-validate/dist/locale/en.json";

export default function defineValidation(Vue) {
  Vue.component("ValidationObserver", ValidationObserver);
  Vue.component("ValidationProvider", ValidationProvider);

  extend("required", {
    ...required,
    params: ["customName"],
    message: (fieldName, placeholders) => {
      return `The ${
        placeholders.customName ? placeholders.customName : fieldName
      } is required`;
    },
  });

  extend("email", { ...email, message: "The field must be a valid email" });
  extend("confirmed", {
    ...confirmed,
    message: "Password and confirm password must be an equal",
  });
  extend("numeric", {
    ...numeric,
    message: "Field must contain only numerics",
  });
  extend("min", { ...min, message: enLocale.messages["min"] });
  extend("max", { ...max, message: enLocale.messages["max"] });
}
