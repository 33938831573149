<script>
export default {
  props: {
    tag: {
      type: String,
      default: "h2",
    },
    dark: Boolean,
    large: Boolean,
    sm: Boolean,
    xs: Boolean,
    xxs: Boolean,
    weight: String,
    withoutSpacing: Boolean,
  },
  functional: true,
  render(h, context) {
    let classes = ["title", context.data.staticClass];
    if (context.props.dark) classes.push("dark");
    if (context.props.large) classes.push("title_large");
    if (context.props.sm) classes.push("title_small");
    if (context.props.xs) classes.push("title_xs");
    if (context.props.xxs) classes.push("title_xxs");
    if (context.props.withoutSpacing) classes.push("title_without-spacing");
    if (context.props.weight)
      classes.push("title_weight-" + context.props.weight);
    return h(context.props.tag, { class: classes }, context.children);
  },
};
</script>

<style lang="scss">
.title {
  letter-spacing: -0.01em;
  line-height: 135%;
  &_large {
    color: $title-large-color;
    font-size: 32px;
    font-family: $font-secondary;
  }
  &_small {
    font-size: 20px;
  }
  &_xs {
    font-size: 16px;
  }
  &_xxs {
    color: $title-color;
    font-size: 14px;
  }
  &_weight {
    &-medium {
      font-weight: 500;
    }
    &-bold {
      font-weight: 700;
    }
  }
  &_without-spacing {
    margin: 0;
  }
}
</style>
