<template functional>
  <div
    class="base-icon-wrapper"
    :class="{ 'base-icon-wrapper_variant_primary': props.variantPrimary }"
  >
    <slot name="icon"></slot>
  </div>
</template>

<script>
export default {
  props: {
    variantPrimary: Boolean,
  },
};
</script>

<style lang="scss">
.base-icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $background;

  $light-primary: #fff7fb;
  &_variant_primary {
    background-color: $light-primary;
  }
}

</style>
