export default {
  props: {
    immediate: {
      type: Boolean,
      default: () => true,
    },
    resource: {
      required: true,
    },
    initialParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (this.immediate) this.loadData({ data: this.initialParams });
  },
  methods: {
    async loadData({ data = {}, config = {} } = {}) {
      this.$emit("update:before");
      try {
        this.loading = true;
        const response = await this.resource(data);
        this.$emit("update:data", { data: response, config });
      } catch (error) {
        throw new Error(error);
      } finally {
        this.loading = false;
      }
      this.$emit("update:after");
    },
  },
  render() {
    return this.$scopedSlots.default({
      loadData: this.loadData,
      loading: this.loading,
    });
  },
};
