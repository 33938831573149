import { createBlockGenerator } from "@aspectus/bem";

const block = createBlockGenerator({ m: "_" })("base-text");

export default {
  name: "BaseText",
  functional: true,
  props: ["appearance", "tag", "size", "variant", "theme", "type", "justify"],
  render(h, context) {
    let classes = [block(context.props), context.data.staticClass];
    return h(
      context.props.tag || "p",
      {
        class: classes || "base-title",
      },
      context.children
    );
  },
};
