<template>
  <router-link :to="homePage" class="logo">
    <svg
      v-if="isAdmin"
      width="136"
      height="16"
      viewBox="0 0 2094 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1225_8425)">
        <path
          d="M1392.04 40.3438L1339.09 153.48C1338.11 155.566 1339.64 157.961 1341.94 157.961H1442.16C1444.46 157.961 1445.98 155.574 1445.02 153.492L1392.38 40.0039L1392.04 40.3438ZM1449.51 183.262H1334.53C1328.73 183.262 1323.46 186.617 1321.01 191.867L1302.38 231.754C1299.93 237.004 1294.66 240.359 1288.86 240.359C1277.89 240.359 1270.67 228.91 1275.4 219.008L1376.02 8.48438C1378.5 3.30078 1383.73 -0.00390625 1389.48 -0.00390625H1396.92C1402.71 -0.00390625 1407.97 3.34375 1410.43 8.57812L1509.25 219.102C1513.9 228.996 1506.68 240.359 1495.75 240.359H1494.93C1489.11 240.359 1483.82 236.973 1481.38 231.688L1463.06 191.934C1460.62 186.648 1455.33 183.262 1449.51 183.262Z"
          :fill="logoColor"
        />
        <path
          d="M1586.24 58.2578V230.004C1586.24 235.535 1581.76 240.02 1576.23 240.02H1564.46C1558.93 240.02 1554.44 235.535 1554.44 230.004V10.3555C1554.44 4.82422 1558.93 0.339844 1564.46 0.339844H1572.58C1575.2 0.339844 1577.72 1.36719 1579.59 3.19531L1760.67 180.344C1762.43 182.066 1765.4 180.816 1765.4 178.352V10.3555C1765.4 4.82422 1769.89 0.339844 1775.42 0.339844H1786.5C1792.03 0.339844 1796.52 4.82422 1796.52 10.3555V230.344C1796.52 235.875 1792.03 240.359 1786.5 240.359H1782.83C1780.2 240.359 1777.69 239.332 1775.81 237.496L1590.98 56.2695C1589.21 54.5391 1586.24 55.7891 1586.24 58.2578Z"
          :fill="logoColor"
        />
        <path
          d="M1957.21 25.9844H1888.16C1885.51 25.9844 1883.36 28.1328 1883.36 30.7852V210.258C1883.36 212.91 1885.51 215.059 1888.16 215.059H1956.52C2004.39 215.059 2062.52 183.945 2062.52 121.375C2062.52 56.7539 2010.2 25.9844 1957.21 25.9844ZM1958.58 240.359H1864.24C1857.05 240.359 1851.22 234.531 1851.22 227.34V14.0469C1851.22 6.85547 1857.05 1.02344 1864.24 1.02344H1963.36C2024.56 1.02344 2093.63 41.3672 2093.63 121.035C2093.63 200.355 2021.15 240.359 1958.58 240.359Z"
          :fill="logoColor"
        />
        <path
          d="M1048.06 0.511719H1054.95C1061.92 0.511719 1067.57 6.16406 1067.57 13.1367V200.555C1067.57 207.527 1073.23 213.18 1080.2 213.18H1227.96C1234.93 213.18 1240.58 218.828 1240.58 225.801V227.223C1240.58 234.195 1234.93 239.848 1227.96 239.848H1048.06C1041.09 239.848 1035.43 234.195 1035.43 227.223V13.1367C1035.43 6.16406 1041.09 0.511719 1048.06 0.511719Z"
          :fill="logoColor"
        />
        <path
          d="M12.625 0.0976562H19.5156C26.4883 0.0976562 32.1406 5.75 32.1406 12.7227V200.141C32.1406 207.113 37.7891 212.766 44.7617 212.766H192.523C199.492 212.766 205.145 218.418 205.145 225.391V226.812C205.145 233.781 199.492 239.434 192.523 239.434H12.625C5.65234 239.434 0 233.781 0 226.812V12.7227C0 5.75 5.65234 0.0976562 12.625 0.0976562Z"
          :fill="logoColor"
        />
        <path
          d="M531.633 0.0976562H538.523C545.496 0.0976562 551.148 5.75 551.148 12.7227V200.141C551.148 207.113 556.801 212.766 563.773 212.766H711.531C718.504 212.766 724.156 218.418 724.156 225.391V226.812C724.156 233.781 718.504 239.434 711.531 239.434H531.633C524.66 239.434 519.008 233.781 519.008 226.812V12.7227C519.008 5.75 524.66 0.0976562 531.633 0.0976562Z"
          :fill="logoColor"
        />
        <path
          d="M362.945 -0.00390625H352.598C347.734 -0.00390625 343.305 2.78906 341.207 7.17969L238.394 222.293C234.391 230.672 240.496 240.359 249.785 240.359H254.887C259.789 240.359 264.25 237.52 266.324 233.078L289.594 183.262L301.559 157.961L356.605 40.3438L356.949 40.0039L411.652 157.961L423.621 183.262L446.566 233.023C448.629 237.496 453.102 240.359 458.027 240.359H463.926C473.176 240.359 479.281 230.742 475.352 222.371L374.375 7.25781C372.297 2.82812 367.84 -0.00390625 362.945 -0.00390625Z"
          :fill="logoColor"
        />
        <path
          d="M882.297 -0.00390625H871.949C867.086 -0.00390625 862.656 2.78906 860.559 7.17969L757.742 222.293C753.738 230.672 759.848 240.359 769.133 240.359H774.234C779.141 240.359 783.598 237.52 785.676 233.078L808.941 183.262L820.906 157.961L875.957 40.3438L876.297 40.0039L931.004 157.961L942.973 183.262L965.914 233.023C967.977 237.496 972.453 240.359 977.379 240.359H983.273C992.523 240.359 998.633 230.742 994.703 222.371L893.723 7.25781C891.645 2.82812 887.191 -0.00390625 882.297 -0.00390625Z"
          :fill="logoColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1225_8425">
          <rect width="2093.63" height="240.36" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <template v-else>
      <div class="auth-logo">
        <img
          v-if="betaVariant"
          class="img-default"
          src="~@/assets/img/logo-beta.png"
          alt="logo"
        />
        <img
          v-else
          class="img-default"
          src="~@/assets/img/lalaland-ai-logo.png"
          alt="logo"
        />
      </div>
    </template>
  </router-link>
</template>

<script>
import ROUTE_NAME from "@/router/router.const";

export default {
  props: {
    isAdmin: Boolean,
    betaVariant: Boolean,
  },
  computed: {
    logoColor() {
      return this.isAdmin ? "#ffffff" : "#020202";
    },
    homePage() {
      return {
        name: this.isAdmin
          ? this.$options.routeName.ADMIN_OVERVIEW
          : this.$options.routeName.OVERVIEW,
        params: {
          layout: this.isAdmin
            ? this.$options.routeName.ADMIN_PANEL
            : this.$options.routeName.CLIENT_PANEL,
        },
      };
    },
  },
  routeName: ROUTE_NAME,
};
</script>

<style lang="scss" scoped>
.auth-logo {
  display: block;
  cursor: pointer;
  width: 136px;
}
</style>
