import { prefixAPI } from "@/api/utils";
const MODEL_AUTH = "auth";
const MODEL_CLIENT = "client";

export const AUTH_TOKEN_URL = prefixAPI(`${MODEL_AUTH}/token/`);

const USER_INFO_URL = prefixAPI(`${MODEL_CLIENT}/users/users/me/`);

// Company Module
const USER_COMPANY_CREATE_URL = prefixAPI(`${MODEL_CLIENT}/company/companies/`);
const USER_COMPANY_URL = prefixAPI(
  `${MODEL_CLIENT}/company/companies/company/`
);
const USER_LEAVE_COMPNAY = prefixAPI(
  `${MODEL_CLIENT}/company/companies/leave/`
);
const COMPANY_INVITES_USER = prefixAPI(`${MODEL_CLIENT}/company/invites/`);
const COMPANY_MEMBERS = prefixAPI(`${MODEL_CLIENT}/company/companies/members/`);
const COMPANY_INVITES_ACCEPT = prefixAPI(
  `${MODEL_CLIENT}/company/invites/accept/`
);
const COMPANY_INVITES_DECLINE = prefixAPI(
  `${MODEL_CLIENT}/company/invites/decline/`
);
const INVITE_BY_CODE = prefixAPI(
  `${MODEL_CLIENT}/company/invites/get_invite_by_code/`
);
const COMPANY_DELETE_USER = prefixAPI(
  `${MODEL_CLIENT}/company/companies/delete_user/`
);
const CLIENT_RECAPTCHA_URI = prefixAPI(`${MODEL_CLIENT}/users/recaptcha/`);
// Company Module

export const USERS_PATH = prefixAPI(`${MODEL_CLIENT}/users/users/`);
const CLIENT_RESET_PASSWORD = prefixAPI(
  `${MODEL_CLIENT}/users/users/reset_password/`
);
const CLIENT_RESET_PASSWORD_CONFIRM = prefixAPI(
  `${MODEL_CLIENT}/users/users/reset_password_confirm/`
);
const EMAIL_CONFIRM = prefixAPI(`${MODEL_CLIENT}/users/users/email_confirm/`);
const USER_CHANGE_PASSWORD = prefixAPI(
  `${MODEL_CLIENT}/users/users/change_password/`
);
const GEO_COUNTRIES = prefixAPI(`${MODEL_CLIENT}/geo/countries/`);
const FAQ_CATEGORIES = prefixAPI(`${MODEL_CLIENT}/garment/categories/`);
const COMPANY_ACTIVE_INVITES = prefixAPI(
  `${MODEL_CLIENT}/company/companies/active_invites/`
);
const COMPANY_DEACTIVE_INVITES = prefixAPI(`${MODEL_CLIENT}/company/invites/`);

export default class UserSvc {
  constructor(api) {
    this.api = api;
  }

  async login(formData) {
    let res = await this.api.postNoAuth(AUTH_TOKEN_URL, {
      ...formData,
    });
    return res;
  }

  async register(formData, url) {
    let data = {};
    for (let field in formData) {
      if (formData[field] === "") continue;
      data[field] = formData[field];
    }
    data.reset_path_prefix = url;
    let res = await this.api.postNoAuth(USERS_PATH, data);
    return res;
  }

  async forgotPassword(formData, url) {
    let res = await this.api.postNoAuth(CLIENT_RESET_PASSWORD, {
      ...formData,
      reset_path_prefix: url,
    });
    return res;
  }

  async emailConfirm(token, uid) {
    let res = await this.api.postNoAuth(EMAIL_CONFIRM, {
      token,
      uid,
    });
    return res;
  }

  async resetPasswordConfirm(formData, token, uid) {
    let res = await this.api.postNoAuth(CLIENT_RESET_PASSWORD_CONFIRM, {
      new_password: formData.new_password,
      token,
      uid,
    });
    return res;
  }

  async getUserData() {
    let res = await this.api.get(USER_INFO_URL);
    return res;
  }

  async updateUserData(formData) {
    let res = await this.api.patch(USER_INFO_URL, {
      ...formData,
    });
    return res;
  }

  async updateAvatar(formData) {
    let res = await this.api.patch(USER_INFO_URL, formData, {
      headers: { "Content-Type": "multipart/form-data;" },
    });
    return res;
  }

  async createCompany(formData) {
    let res = await this.api.post(USER_COMPANY_CREATE_URL, {
      ...formData,
    });
    return res;
  }
  async updateCompany(formData) {
    let res = await this.api.patch(USER_COMPANY_URL, {
      ...formData,
    });
    return res;
  }
  async getCompany() {
    return await this.api.get(USER_COMPANY_URL);
  }
  async getInviteCode(formData) {
    return await this.api.post(INVITE_BY_CODE, {
      ...formData,
    });
  }
  async leaveCompany() {
    return await this.api.delete(USER_LEAVE_COMPNAY);
  }
  async deleteUserCompany(formData) {
    return await this.api.post(COMPANY_DELETE_USER, { ...formData });
  }
  async addUserCompany(formData) {
    return await this.api.post(COMPANY_INVITES_USER, {
      ...formData,
    });
  }
  async userAcceptCompany(formData) {
    return await this.api.post(COMPANY_INVITES_ACCEPT, {
      ...formData,
    });
  }
  async userDeclineCompany(formData) {
    return await this.api.post(COMPANY_INVITES_DECLINE, {
      ...formData,
    });
  }
  async getUserCompanyMembers() {
    return await this.api.get(COMPANY_MEMBERS);
  }
  async userChangePassword(formData) {
    let res = await this.api.post(USER_CHANGE_PASSWORD, {
      ...formData,
    });
    return res;
  }
  async getCountries(data) {
    let res = await this.api.get(GEO_COUNTRIES, {
      params: data,
    });
    return res;
  }
  async getFaqCategories() {
    return await this.api.get(FAQ_CATEGORIES);
  }

  async validateRecaptch(token) {
    return await this.api.postNoAuth(CLIENT_RECAPTCHA_URI, { token });
  }
  async getUserCompanyInvites() {
    return await this.api.get(COMPANY_ACTIVE_INVITES);
  }
  async deleteUserCompanyInvite(id) {
    return await this.api.get(`${COMPANY_DEACTIVE_INVITES}${id}/deactivate/`);
  }
}
