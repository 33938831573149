import CmsService from "@/api/services/cms.service";

export default {
  namespaced: true,

  state: () => ({
    pages: [],
  }),

  getters: {
    page(state) {
      return (pageName) => {
        return state.pages.find((page) => page.type === pageName);
      };
    },
  },

  mutations: {
    updatePages(state, pages) {
      state.pages = pages;
    },
  },
  actions: {
    async loadPages({ commit }, { page, api } = {}) {
      const res = await new CmsService(api).pages(page);
      commit("updatePages", res);
    },
  },
};
