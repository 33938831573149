import {
  HomeIcon,
  NewspaperIcon,
  PhotographIcon,
  DocumentTextIcon,
  RefreshIcon,
  MenuAlt3Icon,
  CogIcon,
  AnnotationIcon,
  BellIcon,
  ChevronDownIcon,
  MailOpenIcon,
  XIcon,
  TrashIcon as TrashIconOutline,
  CloudDownloadIcon,
  ArrowsExpandIcon,
  UserAddIcon,
  EyeIcon,
  PlusSmIcon,
  CalendarIcon,
  SearchIcon,
  EmojiHappyIcon,
  DownloadIcon,
  FolderDownloadIcon,
  FolderAddIcon,
  FilterIcon,
  UserRemoveIcon,
  BookOpenIcon,
  PlusIcon,
  SaveIcon,
  ExclamationCircleIcon as Exclamation,
  ViewListIcon,
  SparklesIcon,
  ChevronDoubleDownIcon,
  PaperClipIcon,
  PaperAirplaneIcon,
  PencilIcon as PencilIconOutline,
  LightningBoltIcon,
} from "@vue-hero-icons/outline";
import {
  MenuAlt3Icon as MenuAlt3IconS,
  ArrowLeftIcon,
  ArrowRightIcon,
  PencilAltIcon,
  TrashIcon,
  DotsVerticalIcon,
  ExclamationCircleIcon,
  ExclamationIcon as ExclamationIconTriangle,
  PencilIcon,
  CheckIcon,
  AcademicCapIcon,
} from "@vue-hero-icons/solid";

export default function (Vue) {
  Vue.component("HomeIcon", HomeIcon);
  Vue.component("NewspaperIcon", NewspaperIcon);
  Vue.component("PhotographIcon", PhotographIcon);
  Vue.component("DocumentTextIcon", DocumentTextIcon);
  Vue.component("RefreshIcon", RefreshIcon);
  Vue.component("MenuAlt3Icon", MenuAlt3Icon);
  Vue.component("MenuAlt3IconS", MenuAlt3IconS);
  Vue.component("CogIcon", CogIcon);
  Vue.component("MailOpenIcon", MailOpenIcon);
  Vue.component("AnnotationIcon", AnnotationIcon);
  Vue.component("BellIcon", BellIcon);
  Vue.component("ArrowLeftIcon", ArrowLeftIcon);
  Vue.component("ArrowRightIcon", ArrowRightIcon);
  Vue.component("ChevronDownIcon", ChevronDownIcon);
  Vue.component("CloseIcon", XIcon);
  Vue.component("PencilAltIcon", PencilAltIcon);
  Vue.component("TrashIcon", TrashIcon);
  Vue.component("TrashIconOutline", TrashIconOutline);
  Vue.component("CloudDownloadIcon", CloudDownloadIcon);
  Vue.component("ArrowsExpandIcon", ArrowsExpandIcon);
  Vue.component("DotsVerticalIcon", DotsVerticalIcon);
  Vue.component("UserAddIcon", UserAddIcon);
  Vue.component("WarningIcon", ExclamationCircleIcon);
  Vue.component("ExclamationIcon", Exclamation);
  Vue.component("ExclamationIconTriangle", ExclamationIconTriangle);
  Vue.component("EyeIcon", EyeIcon);
  Vue.component("PlusSmIcon", PlusSmIcon);
  Vue.component("PencilIcon", PencilIcon);
  Vue.component("PencilIconOutline", PencilIconOutline);
  Vue.component("CheckIcon", CheckIcon);
  Vue.component("CalendarIcon", CalendarIcon);
  Vue.component("SearchIcon", SearchIcon);
  Vue.component("EmojiHappyIcon", EmojiHappyIcon);
  Vue.component("DownloadIcon", DownloadIcon);
  Vue.component("FolderDownloadIcon", FolderDownloadIcon);
  Vue.component("FolderAddIcon", FolderAddIcon);
  Vue.component("FilterIcon", FilterIcon);
  Vue.component("UserRemoveIcon", UserRemoveIcon);
  Vue.component("BookOpenIcon", BookOpenIcon);
  Vue.component("PlusIcon", PlusIcon);
  Vue.component("SaveIcon", SaveIcon);
  Vue.component("ViewListIcon", ViewListIcon);
  Vue.component("SparklesIcon", SparklesIcon);
  Vue.component("ChevronDoubleDownIcon", ChevronDoubleDownIcon);
  Vue.component("PaperClipIcon", PaperClipIcon);
  Vue.component("PaperAirplaneIcon", PaperAirplaneIcon);
  Vue.component("LightningBoltIcon", LightningBoltIcon);
  Vue.component("AcademicCapIcon", AcademicCapIcon);
}
