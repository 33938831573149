import ROUTE_NAME from "@/router/router.const";
import OrderOverview from "@/modules/order-overview/OrderOverview.page";

export default {
  path: "admin-projects/order-overview/:order",
  name: ROUTE_NAME.ORDER_OVERVIEW,
  component: OrderOverview,
  redirect: { name: ROUTE_NAME.ORDER_RESULTS },
  children: [
    {
      name: ROUTE_NAME.ORDER_RESULTS,
      path: "results",
      component: () => import("@/modules/order-overview/OrderResult.page"),
    },
    {
      name: ROUTE_NAME.ORDER_SINGLE_LOOK,
      path: "single/:look",
      component: () => import("@/modules/order-overview/OrderLookSingle.page"),
    },
  ],
};
