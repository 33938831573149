import { prefixAPI } from "@/api/utils";
const CMS_PATH = prefixAPI(`client/mini_cms/pages/`);

export default class UserSvc {
  constructor(api) {
    this.api = api;
  }

  async pages(page = "") {
    let query = {};
    if (page.length) query.type = page;
    let res = await this.api.get(CMS_PATH, { params: query });
    return res;
  }
}
