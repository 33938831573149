<template>
  <header class="header" :style="{ '--bg-color': headerBackGroundColor }">
    <div class="container-fluid">
      <div class="header__row">
        <div
          class="header__col header__col_variant_left"
          :class="{ 'admin-header': isAdmin }"
        >
          <div class="header__logo-row">
            <div class="header__logo-col">
              <div class="header__logo">
                <app-logo betaVariant :is-admin="isAdmin" />
              </div>
              <base-text v-if="isAdmin" size="xxs" variant="white"
                >ADMIN</base-text
              >
            </div>
            <div
              v-if="showHamburger"
              @click="toggleSidebar"
              class="header__hamburger"
              :class="{ header__hamburger_variant_white: isAdmin }"
            >
              <MenuAlt3Icon />
            </div>
          </div>
        </div>
        <div class="header__col header__col_variant_right">
          <div class="header-row">
            <AvailibleLooksWidget />
            <div class="border-item"></div>
            <div class="header-row__col"><NotificateWrapper /></div>
            <div>
              <UserDropDownMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { DESKTOP_WIDTH } from "@/util/const";
import { checkWindowMedia } from "@/util/general";

import { debounce as _debounce } from "@/api/utils";
import { mapActions } from "vuex";

export default {
  props: {
    isAdmin: Boolean,
  },
  data() {
    return {
      showHamburger: true,
    };
  },
  mounted() {
    this.checkLaptop();
    window.addEventListener("resize", this.debounceAction);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.debounceAction);
    });
    this.loadAvailableLooks();
  },
  computed: {
    headerBackGroundColor() {
      return this.isAdmin ? "#18181B" : "#ffffff";
    },
  },
  methods: {
    ...mapActions({
      loadAvailableLooks: "user/loadAvailibleLooks",
    }),
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    checkLaptop() {
      if (checkWindowMedia(DESKTOP_WIDTH)) {
        this.showHamburger = false;
      } else {
        this.showHamburger = true;
      }
    },
    debounceAction: _debounce(function () {
      this.checkLaptop();
    }, 500),
  },
  components: {
    NotificateWrapper: () =>
      import("@/components/widgets/notificate/NotificateWrapper"),
    UserDropDownMenu: () =>
      import("@/components/widgets/notificate/UserDropDownMenu.vue"),
    AvailibleLooksWidget: () =>
      import("@/components/widgets/AvailibleLooksWidget.vue"),
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  background-color: var(--bg-color);
  box-shadow: $card-shadow;
  &__ {
    &logo {
      @extend %element-inliner;
    }
    &logo-row {
      display: flex;
      align-items: flex-end;
      flex: 1;
      justify-content: space-between;
    }
    &logo-col {
      display: flex;
      align-items: flex-end;
    }
    &hamburger {
      display: inline-flex;
      color: $black;
      cursor: pointer;
      &_variant_white {
        color: $white;
      }
    }
    &row {
      padding: $space-2lg 0;

      @extend %d-flex;
      @extend %d-flex_align_center;
    }
    &col {
      &_variant_ {
        &left {
          position: relative;
          flex-basis: $sidebar-width;
          padding-right: $space-2lg;
          width: $sidebar-width;

          @extend %d-flex;
          @extend %d-flex_justify_between;
          &:after {
            @media screen and (min-width: 1200px) {
              position: absolute;
              top: 50%;
              right: 0;
              width: 1px;
              height: 85px;
              background-color: $border;
              content: "";
              transform: translateY(-50%);
            }
          }
          &.admin-header {
            &:after {
              background-color: $gray700;
            }
          }
        }
        &right {
          flex-grow: 1;
        }
      }
    }
  }
}
.header-row {
  @extend %d-flex;
  @extend %d-flex_justify_end;
  @extend %d-flex_align_center;
  &__col {
    margin: 0 20px 0 0;
  }
}
.border-item {
  height: 24px;
  margin: 0 18px;
  border-left: 1px solid $text-dark-color;
}
</style>
