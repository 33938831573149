export function notSimpleType(value) {
  if (value instanceof Object || value instanceof Array) return true;
  return false;
}

export function isObject(value) {
  return value instanceof Object;
}

export function isArray(value) {
  return value instanceof Array;
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getImageSize(url) {
  return new Promise((resolve) => {
    let size = {
      width: null,
      height: null,
    };
    let img = new Image();

    img.onload = function () {
      console.log("asd");
      size.width = this.width;
      size.height = this.height;
      resolve(size);
    };

    img.src = url;
    console.log(url);
  });
}
