import { prefixAPI } from "@/api/utils";
const MODEL = "client/appearance";

const APPEARANCE_COMPLEXION_FILTERS = prefixAPI(
  `${MODEL}/external-catalogues/change_complexion/`
);
const APPEARANCE_FACE_FILTERS = prefixAPI(
  `${MODEL}/external-catalogues/change_face_type/`
);
const APPEARANCE_FUTURES_FILTERS = prefixAPI(
  `${MODEL}/external-catalogues/change_futures/`
);
const APPEARANCE_POSES_URL = prefixAPI(`${MODEL}/poses/`);
const MODEL_TEMPLATES_URL = prefixAPI(`${MODEL}/model-templates/`);
const APPEARANCE_MODELS_COUNT_URI = prefixAPI(
  `${MODEL}/model-templates/counts/`
);
const APPEARANCE_EXTERNAL_FIRST_PARAMS_URL = prefixAPI(
  `${MODEL}/external-catalogues/first/`
);
const APPEARANCE_POSES_FILTERS_URL = prefixAPI(`${MODEL}/poses/filters/`);
const APPEARANCE_MODELS_FILTERS_URL = prefixAPI(`${MODEL}/models/filters/`);

export default class ModelSvc {
  constructor(api) {
    this.api = api;
  }
  async getInitialParams() {
    const res = await this.api.get(APPEARANCE_EXTERNAL_FIRST_PARAMS_URL);
    return res;
  }
  async getPosesParams(data) {
    const res = await this.api.get(APPEARANCE_POSES_URL, {
      params: data,
    });
    return res;
  }
  async changeComplexion(data) {
    const res = await this.api.get(APPEARANCE_COMPLEXION_FILTERS, {
      params: data,
    });
    return res;
  }
  async changeFace(params) {
    const res = await this.api.get(APPEARANCE_FACE_FILTERS, {
      params,
    });
    return res;
  }
  async changeFutures(params) {
    const res = await this.api.get(APPEARANCE_FUTURES_FILTERS, {
      params,
    });
    return res;
  }
  async createModel(formData) {
    const { id, poses } = formData;
    const apperanceData = {
      external_catalogue: id,
      name: "",
      poses,
    };
    return await this.api.post(MODEL_TEMPLATES_URL, apperanceData);
  }
  async appearanceParams(id) {
    const res = await this.api.get(`${MODEL_TEMPLATES_URL}${id}/`);
    return res;
  }
  async appearanceUpdate(idModel, formData) {
    const res = await this.api.patch(
      `${MODEL_TEMPLATES_URL}${idModel}/`,
      formData
    );
    return res;
  }
  async deleteModel(id) {
    await this.api.delete(`${MODEL_TEMPLATES_URL}${id}/`);
  }

  async getPoseFilters() {
    return await this.api.get(APPEARANCE_POSES_FILTERS_URL);
  }
  async getModelFilters() {
    return await this.api.get(APPEARANCE_MODELS_FILTERS_URL);
  }
  async getModelsCount() {
    return await this.api.get(APPEARANCE_MODELS_COUNT_URI);
  }
  async modelTemplates(params = {}) {
    return await this.api.get(MODEL_TEMPLATES_URL, { params });
  }
}
