export default {
  mounted() {
    const listener = (e) => {
      if (e.target === this.$el || this.$el.contains(e.target)) {
        return;
      }

      this.$emit("click:outside");
    };
    document.addEventListener("click", listener, { passive: true }, true);
    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("click", listener);
    });
  },
  render() {
    // return this.$slots.default[0];
    return this.$scopedSlots.default();
  },
};
