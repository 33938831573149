import ROUTE_NAME from "@/router/router.const";

export default {
  path: "settings",
  name: ROUTE_NAME.SETTINGS,
  redirect: { name: ROUTE_NAME.PROFILE },
  component: () => import("@/modules/settings/components/SettingsUserLayout"),
  children: [
    {
      path: "profile",
      name: ROUTE_NAME.PROFILE,
      component: () =>
        import("@/modules/settings/components/SettingsUserProfile"),
    },
    {
      path: "organisation",
      name: ROUTE_NAME.ORGANISATION,
      component: () =>
        import("@/modules/settings/components/SettingsUserOrganisation"),
    },
    {
      path: "team-members",
      name: ROUTE_NAME.TEAM_MEMBERS,
      component: () =>
        import("@/modules/settings/components/SettingsUserTeamMembers"),
    },
    {
      path: "change",
      name: ROUTE_NAME.CHANGE,
      component: () =>
        import("@/modules/settings/components/SettingsChangePassword"),
    },
    {
      path: "billing-settings",
      name: ROUTE_NAME.DASHOBARD_BILLING_SETTINGS,
      component: () => import("@/modules/settings/components/SettingsBillings"),
    },
    {
      path: "billing-settings-handle-payment",
      name: ROUTE_NAME.DASHOBARD_BILLING_SETTINGS_PAYMENT_HANDLE,
      component: () =>
        import("@/modules/settings/components/SettingsHandlePayment"),
    },
  ],
};
