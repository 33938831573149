import { createBlockGenerator } from "@aspectus/bem";
import { mergeContext } from "@aspectus/vue-utils";

const block = createBlockGenerator({ m: "_" })("svg-icon");

export default {
  name: "svg-icon",
  functional: true,
  props: ["name", "appearance", "size", "variant", "theme", "view", "type"],
  render(h, context) {
    return h(
      "svg",
      mergeContext(context.data, {
        class: block(context.props) || "svg-icon",
      }),
      [
        h("use", {
          attrs: {
            ["xlink:href"]: `#icon-${context.props.name}`,
          },
        }),
      ]
    );
  },
};
