export const confirmModal = (context, informData) => {
  return new Promise((resolve, reject) => {
    context
      .$confirm(
        informData.message || "Continue?",
        informData.title || "Warning",
        {
          confirmButtonText: informData.confirmButtonText || "OK",
          cancelButtonText: informData.cancelButtonText || "Cancel",
          type: informData.type || "warning",
        }
      )
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
};
