<template functional>
  <div class="avatar-wrapper" :class="props.className">
    <div class="avatar-wrapper__preview">
      <img
        class="avatar-wrapper__image"
        v-if="props.previewImg"
        :src="props.previewImg"
        alt="user"
      />
      <img
        class="avatar-wrapper__image"
        v-else
        src="~@/assets/img/no-avatar.png"
        alt="user"
      />
    </div>
    <div v-if="props.hasNotificate" class="avatar-wrapper__notificate"></div>
  </div>
</template>

<script>
export default {
  props: {
    previewImg: {
      type: String,
    },
    hasNotificate: {
      type: Boolean,
      default: () => false,
    },
    className: {
      type: [String],
    },
  },
};
</script>

<style lang="scss">
.avatar-wrapper {
  position: relative;
  width: var(--el-size);

  --el-size: 40px;
  &_size_sm {
    --el-size: 32px;
  }
  &__ {
    &preview {
      display: inline-flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      width: var(--el-size);
      height: var(--el-size);
      border-radius: 50%;
      background: linear-gradient(135deg, #e7f9fb 0%, #fce5f0 100%);
    }
    &image {
      max-width: 100%;
      max-height: 100%;

      @extend %full-image;
    }
    &notificate {
      position: absolute;
      top: 0;
      right: 5px;
      width: 8px;
      height: 8px;
      border: 2px solid $white;
      border-radius: 50%;
      background-color: $primary;
    }
  }
}

</style>
