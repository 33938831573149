export const API_BASE_URL = process.env.VUE_APP_BASE_URL_API;
export const prefixAPI = (url) => API_BASE_URL + url;

export const formattedDateWithTime = (dayjs, date) => {
  return dayjs(date).format("DD MMM YYYY hh:mm A");
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function sizeInMb(size) {
  return (size / (1024 * 1024)).toFixed(1);
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const imageSize = (url) => {
  const image = new Image();
  image.src = url;
  return new Promise((resolve, reject) => {
    image.addEventListener("load", () => {
      const width = image.naturalWidth;
      const height = image.naturalHeight;
      resolve({ width, height });
    });
    image.onerror = (error) => reject(error);
  });
};

export function validMinResolution({ width, height }, requiredResolution) {
  return width >= requiredResolution || height >= requiredResolution;
}

export function transformInvalidFieldMessage(error) {
  const invalidFields = error?.invalid_fields ?? null;
  if (invalidFields) {
    return Object.keys(invalidFields).reduce((acc, key) => {
      acc += invalidFields[key].join("/");
      return acc;
    }, "");
  } else {
    return error.message;
  }
}

export function getFilenameWithoutExtension(filename) {
  return filename.slice(0, filename.indexOf("."));
}

export function getFileExtension(filename) {
  return filename.split(".").pop();
}

export async function getTiffArrayBuffer(file) {
  let blob = new Blob([file], { type: "image/tiff" });
  let buffer = await blob.arrayBuffer();
  return buffer;
}

export function getDifferenceDate(dayjs, d1, d2, unit) {
  return dayjs(d1).diff(dayjs(d2), unit);
}

export function checkPriceStringZero(floatStringPrice) {
  const parsedToIntValue = Number(floatStringPrice);
  const zero = 0;
  return parsedToIntValue === zero;
}
