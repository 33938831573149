const wordEnd = (value) => {
  const min = 1;
  return value > min ? "s" : "";
};
const transformToLowerCase = (value) => value.toLowerCase();
const getPeriodNameByMonth = (months, suffix = "") => {
  switch (months) {
    case 1:
      return "month" + suffix;
    case 12:
      return "year" + suffix;
    default:
      return "";
  }
};

export const install = (Vue) => {
  Vue.filter("wordEnd", wordEnd);
  Vue.filter("transformToLowerCase", transformToLowerCase);
  Vue.filter("getPeriodByMonths", getPeriodNameByMonth);
};

export default { install };
