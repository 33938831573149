// import UserSvc from "@/api/services/user.service";

export default {
  namespaced: true,

  state: () => ({
    types: [],
    perspectives: [],
  }),

  mutations: {
    updateTypes(state, payload) {
      state.types = payload;
    },
    updatePerspectives(state, payload) {
      state.perspectives = payload;
    },
  },
};
