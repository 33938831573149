<template>
  <div class="error-wrapper">
    <div class="control-hint" v-show="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: {
    error: String,
  },
};
</script>

<style scoped>
.error-wrapper {
  position: relative;
  margin-top: 14px;
}

</style>
