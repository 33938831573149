<script>
export default {
  render() {
    return this.$slots.default;
  },
  data: () => ({
    err: null,
  }),
  errorCaptured: function (err) {
    this.err = err;
    this.$notify.error({
      message: err.message,
    });
    return false;
  },
};
</script>
