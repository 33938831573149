export default {
  name: "UiToggler",
  data() {
    return {
      activeState: this.active,
    };
  },
  props: {
    active: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  watch: {
    active(nVal) {
      this.activeState = nVal;
    },
  },
  methods: {
    toggleActive() {
      this.activeState = !this.activeState;
      this.$emit("active:toggled", this.activeState);
    },
    collapse(state = false) {
      this.activeState = state;
      this.$emit("active:collapsed", this.activeState);
    },
    setState(state) {
      this.activeState = state;
      this.$emit("active:changed", this.activeState);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeState = this.active;
    });
  },
  render() {
    return this.$scopedSlots.default({
      active: this.activeState,
      toggleActive: this.toggleActive,
      collapse: (val) => this.collapse(val),
      setState: (val) => this.setState(val),
    });
  },
};
