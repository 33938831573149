<template>
  <div class="app-field" :class="className">
    <div v-if="label" class="app-field__label">
      <slot name="label">
        {{ label }}
        <span class="app-field__tip">{{ tip }} </span>
      </slot>
    </div>
    <el-input
      class="base-search"
      v-bind="{ ...$props, ...$attrs }"
      @input="$emit('input', $event)"
    >
      <template #suffix><SearchIcon /></template>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    className: String,
    label: String,
    tip: String,
  },
};
</script>

<style lang="scss">
.base-search {
  .el-input {
    &__ {
      &suffix {
        display: inline-flex;
        align-items: center;
      }
      &inner {
        border-radius: $--border-radius-base;
        background-color: $background;
        &::placeholder {
          font-weight: 500;
          color: #928e9e;
        }
      }
    }
  }
  &_ {
    &bg_white {
      .el-input {
        &__inner {
          background-color: $white;
        }
      }
    }
    &radius_sm {
      .el-input {
        &__inner {
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
