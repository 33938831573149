<template>
  <div class="page-content">
    <DefaultHeader @toggle-sidebar="sidebarActive = !sidebarActive" />
    <PaymentFailedBanner v-if="userPaymentFailed" />
    <main class="main main_d-flex">
      <div class="dashboard-content">
        <div class="dashboard-content__row">
          <div
            class="dashboard-content__sidebar"
            :class="{ isActive: sidebarActive }"
          >
            <SideBar @collapse="sidebarActive = !sidebarActive" />
          </div>
          <div class="dashboard-content__inner">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import DefaultHeader from "@/components/layouts/DefaultHeader.vue";
import SideBar from "@/components/ui/elements/SideBar.vue";

export default {
  mounted() {
    const delay = 10000;
    this.timer = setInterval(() => {
      this.updateNotify();
    }, delay);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  data() {
    return {
      sidebarActive: false,
      timer: null,
    };
  },
  computed: {
    userPaymentFailed() {
      return this.$store.getters["user/userPaymentFailed"];
    },
  },
  methods: {
    updateNotify() {
      this.$store.dispatch("user/updateNotifications");
    },
  },
  components: {
    DefaultHeader,
    SideBar,
    PaymentFailedBanner: () =>
      import("@/components/widgets/PaymentFailedBanner.vue"),
  },
};
</script>

<style lang="scss">
.dashboard-content {
  flex-grow: 1;
  width: 100%;
  &__ {
    &row {
      height: 100%;

      @extend %d-flex;
    }
    &sidebar {
      flex-basis: calc(#{$sidebar-width} + #{$container-inner});
      flex-shrink: 0;
      width: calc(#{$sidebar-width} + #{$container-inner});
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        position: absolute;
        top: $header-height;
        left: 0;
        z-index: $z-index-2;
        height: calc(100% - #{$header-height});
        transition: transform $transition-secondary;
        transform: translateX(
          calc((#{$sidebar-width} + #{$container-inner}) * -1)
        );
      }
      &.isActive {
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          transform: translateX(0);
        }
      }
    }
    &inner {
      display: flex;
      flex: auto;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
    }
  }
}
</style>
