<script>
export default {
  functional: true,
  render(h, context) {
    let classes = ["divider", context.data.staticClass];
    return h("div", { class: classes }, context.children);
  },
};
</script>

<style lang="scss">
.divider {
  width: 100%;
  height: 1px;
  background-color: $divider;
  &_offset-vertical_md {
    @extend %m_vertical_md;
  }
  &_offset-vertical_lg {
    @extend %m_vertical_lg;
  }
}
</style>
