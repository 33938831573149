<template>
  <div class="page-content">
    <OrderHeader />
    <PaymentFailedBanner v-if="userPaymentFailed" />
    <main class="order-page">
      <div class="order-page__content">
        <app-loader v-if="loading" />
        <router-view v-if="!loading"></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ROUTE_NAME from "@/router/router.const";
import OrderHeader from "@/modules/order/components/OrderHeader.vue";

import { PROJECT_STATUS } from "@/util/const.js";

import { confirmModal } from "@/util/vue-utils";

import store from "@/store/index";

const leaveOrderGuardExceptions = [
  ROUTE_NAME.ORDER_FINISH,
  ROUTE_NAME.ORDER_THANK,
  ROUTE_NAME.ORDER_INVOICE,
];

export default {
  inject: ["api"],
  data: () => ({
    loading: true,
  }),
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch("user/loadUser");
      next();
    } catch (error) {
      if (error.code === 401) {
        next({
          name: ROUTE_NAME.LOGIN,
        });
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (leaveOrderGuardExceptions.includes(from.name)) return next();
    try {
      await confirmModal(this, {
        title: "Finish later",
        message: "Finish project later?",
      });
      next();
    } catch (error) {
      return false;
    }
  },
  async created() {
    await this.loadData();
    if (this.orderFinished) {
      this.goFinish();
      return;
    }
  },
  mounted() {
    this.loadAvailableLooks();
    this.updateNotifications();
    this.getCounts();
  },
  beforeDestroy() {
    this.clearLooks();
  },
  computed: {
    ...mapState({
      order: (state) => state.order.order,
    }),
    orderFinished() {
      return (
        this.order.is_paid ||
        this.order.status !== PROJECT_STATUS.IS_INITIAL.value
      );
    },
    userPaymentFailed() {
      return this.$store.getters["user/userPaymentFailed"];
    },
  },
  methods: {
    ...mapActions({
      loadOrder: "order/loadOrder",
      clearLooks: "look/clearLooks",
      loadUser: "user/loadUser",
      loadAvailableLooks: "user/loadAvailibleLooks",
      getCounts: "order/fetchModelsCount",
      updateNotifications: "user/updateNotifications",
    }),

    async loadData() {
      this.loading = true;
      try {
        await this.loadOrder({ api: this.api, id: this.$route.params.order });
      } catch (error) {
        this.$router.push({ name: ROUTE_NAME.NOT_FOUND_GENERAL });
        throw new Error(error.message);
      } finally {
        this.loading = false;
      }
    },
    goFinish() {
      const routeNonRedirectCondition = [
        ROUTE_NAME.ORDER_FINISH,
        ROUTE_NAME.ORDER_INVOICE,
        ROUTE_NAME.ORDER_THANK,
      ];
      if (routeNonRedirectCondition.includes(this.$route.name)) return;
      this.$router.push({
        name: ROUTE_NAME.ORDER_FINISH,
        params: { order: this.order.id },
      });
    },
  },
  components: {
    OrderHeader,
    PaymentFailedBanner: () =>
      import("@/components/widgets/PaymentFailedBanner.vue"),
  },
};
</script>

<style lang="scss">
.order-page {
  background-color: $background;
  text-align: left;
  &_spacing {
    margin-top: 24px;
  }
}

.order-files {
  height: 100%;
  &_header {
    display: flex;
    justify-content: space-between;
  }
  &__subtitle {
    color: $field-tip;
    text-transform: uppercase;
    font-size: 12px;
  }
}

.order-card {
  min-height: 300px;
  height: 100%;
}

.order-layout-content-height {
  height: calc(100vh - #{$order-layout-height});
}
</style>
